#call-to-action{
    /* margin: 4em 0; */
    #cta-btn {
        .box {
            width: 100%;
            height: 185px;
            padding: 20px 15px;
            border: #fff solid 2px;
            border-radius: 15px;
            transition: all 0.5s ease-in-out;
            text-align: center;
            box-shadow: 0 0 10px #cfcfcf;
            background-color: white;
            margin-bottom: 20px;
            @media (max-width: 767px){
              width: 250px;
              height: auto;
              margin: 20px auto;
            }
            .label {
                font-weight: bold;
                color: #707070;
                font-size: 1em;
                padding: 0;
                margin: 1.2em 0 0 0;
                white-space: normal;
                @media (max-width: 767px){
                  margin: 2em 0 0 0;
                }
            }
            &:hover {
              border: #8fc73e solid 2px;
              text-decoration: none;
              .label {
                color: black;
              }
                #rocket-body{
                   transform: translate(8px, -8px);
                }
                #rocket-lines{
                   transform: translate(5px, -5px);
                }
                #bubble {
                  transform: rotateY(180deg);
                }
              
                #caret {
                  transform: translateX(30px);
                }
                #underscore {
                  transform: translateX(-20px);
                }
              #head-back {
                opacity: 0;
              }
              #head-front {
                opacity: 1;
              }
              #arrow {
                transform: translateY(-3px);
              }
              #cloud {
                transform: translateY(3px);
              }
              #swirl {
                transform: rotate(180deg);
              }
            }
        }
          
        svg {
            margin: 0 auto;
            min-height: 80px;
            #rocket-body {
              transition: all 0.3s ease-out;
            }
            #rocket-lines {
              transition: all 0.3s ease-in-out;
              #line1 {  
                transform-origin: 50% 50%;
                transform: translateX(0 0);
              }
            }
            
            #bubble {
              transform-origin: 50% 50%;
              transition: all 0.3s ease-out;
            }
            
            #caret {
              transition: all 0.3s ease-in;
            }
            #underscore {
              transition: all 0.3s ease-in-out;
            }
            
            #head-front {
              transition: all 0.2s ease-in-out;
              opacity: 0;
            }
            #head-back {
              transition: all 0.2s ease-in-out;
            }
            #arrow, #cloud {
              transition: all 0.3s ease-in-out;
            }
            #swirl {
              transition: all 0.3s ease-in-out;
              transform-origin: 330px 180px;
            }
        }
        #dino{
            margin-top: -10px;
        }
        #software {
            padding-top: 10px;
        }
    }
}
.ninja {
  background-image: url('../../images/ninja-float.svg');
  width: 130px;
  height: 250px;
  position: absolute;
  top: 30%;
  right: 5%;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}
.floating {
  animation: float ease-in-out 5s infinite;
  -webkit-animation: float ease-in-out 5s infinite;
  -moz-animation: float ease-in-out 5s infinite;
  -o-animation: float ease-in-out 5s infinite;
  -ms-animation: float ease-in-out 5s infinite;
}
@keyframes float {
  0% {
      transform: translatey(0px);
  }
  50% {
      transform: translatey(-15px);
  }
  100% {
      transform: translatey(0px);
  }
}

#what-we-do{
  background: rgba(13,64,124,1);
  background-image: url("../../images/home-stars.svg"); /* fallback */
  background: url("../../images/home-stars.svg") no-repeat bottom fixed, linear-gradient(180deg, rgba(13,64,124,1) 0%, rgba(16,33,67,1) 100%);
  background-size: cover;
  margin-top: 150px;
  z-index: 10;
  position: relative;
  color: white;
  margin-bottom: 0;
  .pt-150 {
    padding-top: 120px;
  }
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    min-height: 120px;
    left: 0;
    background: url("../../images/upper-waves.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: inherit;
  }   
  .glow-bg {
    background: url("../../images/backglow.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding: 75px;
    @media (max-width: 768px) {
      padding: 30px;
      background-size: cover;
    }
    img {
      max-height: 145px;
      margin: 0 auto;
      @media (max-width: 768px) {
        max-height: 110px;
      }
    }
  }
}
#stats{
  margin-top: 0;
}
#our-clients{
    margin: 150px 0;
}
#events-section{
    margin: 0px 0px;
}
#testimonials {
    margin: 150px 0;
}


