.learn-courses-container {
    background: url("../../../images/learn/overview/bg-learn-overview.png") top center no-repeat;
    padding: 0px 0px 141px 0px;
    background-size: cover;
}

.learn-courses-container::before {
    content: "";
    display: inline-block;
    background: url("../../../images/learn/overview/wave-overview.svg") top center no-repeat;
    background-size: contain !important;
    height: 210px;
    width: 100%;
}

#courses-logo {
    text-decoration: none;
    &:hover img {
        content: url("../../../images/learn/overview/logo-ortus-courses-hover.svg");
    }
    &:hover span {
        @apply tw-text-gray-700;
    }
}

#cfcasts-logo {
    text-decoration: none;
    &:hover img {
        content: url("../../../images/learn/overview/logo-ortus-cfcasts.svg");
    }
    &:hover span {
        @apply tw-text-gray-700;
    }
}

#books-logo {
    text-decoration: none;
    &:hover img {
        content: url("../../../images/learn/overview/logo-ortus-books-hover.svg");
    }
    &:hover span {
        @apply tw-text-gray-700;
    }
}

#docs-logo {
    text-decoration: none;
    &:hover img {
        content: url("../../../images/learn/overview/logo-ortus-docs-hover.svg");
    }
    &:hover span {
        @apply tw-text-gray-700;
    }
}

@media (max-width: 576px) {
    .learn-courses-container::before {
        height: 130px;
    }
}

.btn-courses-book {
    padding: 3px 25px;
    border-radius: 4px;
}