.webinar-head {
    background-image: url('../../../images/events/webinar/topbanner.svg');
    background-size: cover;
    height: 327px;
}

.webinar-bg-card {
    background: url('../../../images/events/webinar/bg-webinar-card.png');
    background-size: cover;
    background-position: top center;

    &:hover {
        background: url('../../../images/events/webinar/bg-webinar-hover.svg') top center;
        background-size: cover;
    }
}

.webinar-cards {
    display: flex;
    flex-wrap: wrap;
}

.webinar-card {
    width: 300px;
    margin: 10px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
    border-radius: 12px;
    position: relative;
    margin-top: 40px;
}


.box-logo {
    width: 70px;
    height: 70px;
    margin-top: -11%;
    position: absolute;
    left: 38%;
    z-index: 1;
    padding: 1px;
}

.box-img-detail {
    width: 100%;
    height: auto;
}

.box-img-upcoming {
    height: auto;
}

.flex-basic-30 {
    flex-basis: 30%;
}

@supports(object-fit: cover){
    .box-logo img {
        height: 100%;
        object-fit: contain;
        object-position: center center;
    }

    .box-img-detail, .box-img-upcoming img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

}

.img-upcoming {
    width: 600px;
    height: 100%;
    object-fit: fill;
}

.control-webinar-filter {
    border: 0;
    border-bottom: 1px solid silver;
    background: transparent;
}

.img-vertical-align {
    vertical-align: middle;
}

/* Hide the images by default */
.mySlides {
    display: none;
    width: 100%;
    height: 308px;
    max-width: 1140px;
    background: #EFEFEF;
    border-radius: 16px;
    margin-bottom: 20px;
}
.webinardata{ display: flex; }

.webinar-info {
    padding: 20px;
}

.webinar-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.action-container {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.webinar-thumbnail {
    width: 85px;
}

.thumbnails-container {
    display: flex;
    gap: 15px;
    justify-content: center;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
    cursor: pointer;
}

/* Add a transparency effect for thumnbail images */
.demo {
    opacity: 0.6;
}

.active,
.demo:hover {
    opacity: 1;
}

@media(max-width: 992px){
    .mySlides {
        height: auto;
    }
    .webinar-img {
        text-align: center;
    }
    .webinar-img img {
        width: 100%;
        max-width: 550px;
    }
    .webinardata {
        flex-direction: column;
    }
}

@media (max-width: 880px) {

}

@media (max-width: 768px) {
    .webinar-cards {
        justify-content: center;
    }
}

@media (max-width: 600px) {

}