.error {
    height: 15px;
    background-color: #efa7b4;
    border: 1px solid red;
    font: normal 12px Arial, Helvetica, sans-serif;
    color: #000;
    padding: 3px 10px;
    /* CSS3 spicing for mozilla and webkit */
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-box-shadow: 0 0 6px #ddd;
    -webkit-box-shadow: 0 0 6px #ddd;
}

.cbox_messagebox {
    font-size: 13px !important;
    font-weight: bold !important;
    margin: 10px !important;
}

.cbox_messagebox_info {
    background: #D1E6EF url(../../images/cmsg.gif) no-repeat scroll .5em 50%;
    border: 1px solid #2580B2;
    margin: 0.3em;
    padding: 0pt 1em 0pt 3.5em;
}

.cbox_messagebox_warning {
    background: #FFF2CF url(../../images/wmsg.gif) no-repeat scroll .5em 50%;
    border: 1px solid #2580B2;
    margin: 0.3em;
    padding: 0pt 1em 0pt 3.5em;
}

.cbox_messagebox_error {
    background: #FFFFE0 url(../../images/emsg.gif) no-repeat scroll .5em 50%;
    border: 1px solid #2580B2;
    margin: 0.3em;
    padding: 0pt 1em 0pt 3.5em;
}