:root {
    --ortus-ttl-blue: #0F347E;
    --ortus-green: #8fc73e;
    --ortus-gray: #5C5C5C;
    --lightgray: #B4B4B4;
    --lightgray-txt: #868686;
    --portf-bg: #082766;
    --lightblue-bg: #0B3188;

    --quote-onlightbg:#E4F1F6;
    --quote-ondarkbg:#273B64;

    --alert-bg: #007FAA;
    --cards-bg: #ECECEC;
    --text-blue: #082766;
}

.got-question-government {
  background: #082766 url("../../images/services/government/bg-waves-blue-gray.svg") top center no-repeat;
  padding-bottom: 200px;
  margin-top: 150px;
  background-size: auto;
  .pj-txt {
      max-width: 600px;
      a {
          color: var(--ortusgreen);
          &:hover {
              color: #00dee6;
              text-decoration: none;
          }
      }
  }
}

.bg-coldbox-contentbox{
  background: #fff url("../../images/services/government/bg-coldbox-contentbox.svg") top center no-repeat;
  background-size: contain;
  height: calc(100% - 240px);
  padding: 50px;
  min-height: 440px
}

.btn-custom {
  margin: 0px !important;
  padding: 8px 11px !important;
}

.bg-testimonials {
    background-color: var( --quote-onlightbg );
}

.bg-cards {
    background-color: var( --cards-bg );
}

.bg-portfolio {
    background-color: var( --portf-bg );
}

.bg-past-performance {
  background-color: #F0F0F0;
}

.bg-alert {
    background-color: var( --alert-bg );
}

.bg-lightblue {
    background-color: var( --lightblue-bg );
}

.list-plus {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-plus > li {
    padding-left: 16px;
    font-size: 18px;
    margin-bottom: 10px;
}

.list-plus > li::before {
    content: "+";
    padding-right: 8px;
    color: #8fc73e;
    font-size: 23px;
    font-weight: bolder;
}

.bg-green{background-color: var(--ortus-green);}

.services-head {
    background-image: url("../../images/services/bg-services-head.svg");
    background-size: cover;
}
.img-head-legacy{max-width: 380px;width: 100%;height: auto;}
.img-head-cloud{max-width: 440px;width: 100%;height: auto;}
.img-head-perf{max-width: 480px;width: 100%;height: auto;}
.img-head-train{max-width: 460px;width: 100%;height: auto;}

.mycontainer{padding: 20px;}
*,
*:hover {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.link-gray{ color:var(--lightgray); font-weight: bold; display: inline-flex;}
.link-gray:hover, .link-gray:hover svg{color: var(--ortus-green); fill: var(--ortus-green);}

.breadcrumbs {margin-top: 0px;}
.breadcrumbs a,.breadcrumbs i::before {color: #fff;}

.serv-box, .supportandcons {border: 1px solid #E6E6E6;border-radius: 20px;box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.07);padding: 20px;}

.serv-ico {height: 80px;justify-content: center;align-items: center;}
.serv-box-ttl {font-size: 24px;font-weight: bold;color: var(--ortus-ttl-blue);margin-bottom: 0;}
.serv-box-desc {height: 80px;margin-bottom: 5px;}
.serv-box-link {width: 105px;padding: 5px;margin: 0 auto;}
.supportandcons .serv-box-link {width: 105px;padding: 0px;margin: 0;}
.serv-box:hover, .supportandcons:hover {border: 1px solid var(--ortus-ttl-blue);background-color: var(--ortus-ttl-blue);}
.servbox-ico {height: 60px;}
.serv-box-desc-short { height: 68px !important;}
.serv-support:hover {
  .serv-box-ttl, .serv-box-desc {
    color: #fff;
  }
}

.serv-box:hover .servbox-ico,
.serv-box:hover .serv-box-ttl,
.serv-box:hover .serv-box-desc,
.supportandcons:hover .servbox-ico,
.supportandcons:hover .supp-ttl,
.supportandcons:hover .supp-txt {fill: #fff;color: #fff;}

.serv-box:hover .serv-box-link,
.serv-box:hover, .supportandcons:hover .serv-box-link,
.supportandcons:hover:hover {color: var(--ortus-green);fill: var(--ortus-green);}


.supportandcons{padding: 40pxS;}
.supp-desc {max-width: 460px;width: 100%; margin: 0 auto;}
.supp-ttl {font-size: 32px;color: var(--ortus-ttl-blue);font-weight: bold;margin: 0;color: var(--ortus-ttl-blue);}
.supp-txt {font-size: 22px;color: var(--ortus-ttl-blue);}
#support-img {width: 100%;height: auto;max-width: 510px;}

.project-inmind {width: 100%;max-width: 490px;margin: 30px auto 0;}
.project-inmind .pj-ttl {color: var(--ortus-gray);}
.project-inmind p {margin-bottom: 0px;}

.protfolio-bgtop {
    background: url("../../images/services/bg-protfolio.svg") top left no-repeat;
    height: 160px;
}

.bg-curve-header {
    background: url("../../images/services/government/bg-waves-gray.svg") top left no-repeat;
    height: 132px;
}

.support-ninja {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
}

.support-ninja img {
    max-width: 211px;
    width: 60%;
}

.support-ninja-info {
    width: 275px;
}

.support-ninja-info .support-ninja-ttl {
    font-size: 32px;
    font-weight: bold;
    margin: 0px;
}

.bluebg {
    background-color: #0F347E;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 142px;
    padding: 65px 10px 10px;
    box-sizing: border-box;
    margin-top: -65px;
}

.bluebg p {
    color: #fff;
    font-size: 38px;
    font-weight: bold;
    margin: 0px;
}

.bluebg .btn-primary {
    text-align: center;
    font-weight: bold;
    width: 150px;
    text-decoration: none;
}

.protfolio-cont {background: var(--portf-bg);padding-bottom: 100px;}
.portf-head {border-bottom: 1px solid #fff; padding-bottom: 5px;margin-bottom: 35px;}
.portf-ttl {color: #fff;margin: 0px;}
.port-serv-select {width: 350px;color: #fff;height: 45px;border-radius: 8px;}
.portf-search-cont {width: 350px;height: 45px;}
#searchportf,
.searchportf {border-top-left-radius: 8px;border-bottom-left-radius: 8px;border: none;padding: 0.5rem 0.75rem;}
.portf-searchbtn {padding: 0 20px;background-color: #3B95FF;border-top-right-radius: 8px;border-bottom-right-radius: 8px;border: none;}
.portf-searchbtn:hover {background-color: var(--ortus-green);}
.project {width: 100%;height: 272px;background: #fff;border-radius: 10px;border: 1px solid transparent;margin-bottom: 25px;}
.project:hover {background: transparent;border: 1px solid #fff;}
.proj-img img {width: 570px;height: 270px;border-radius: 10px;}
.project:hover .proj-desc,
.project:hover .pro-ttl,
.project:hover .pro-subttl {color: #fff;}
.project:hover a {color: var(--ortus-green);}

.pro-ttl {font-size: 32px;font-weight: bold;margin-bottom: 0px;line-height: 0.8;color: var(--portf-bg);}
.pro-subttl {font-size: 22px;color: var(--portf-bg);}
.proj-desc {color: var(--lightgray-txt);}


.desc-list {list-style-image: url("../../images/services/bullet-list-detailpage.svg");}
.desc-list li {font-size: 18px;margin-bottom: 10px;}
.detail-desc-cont div img {margin: 0 auto;}


.portfolio-detail h1{color: var(--ortus-green); line-height: 0.4;}
.portfolio-detail p{font-size: 18px;}
.portfolio-detail img{border-radius: 10px;}


.about-container{max-width: 1730px; margin: 40px auto;}
.about-left{width: 260px; height: auto; padding: 20px;}
.about-left img{width: 100%; max-width:200px; margin-top: 30px; }
.about-right{width: 260px; height: auto;text-align: center;padding: 20px;}
.about-up, .about-down{display: none}
 

.quote-cont{width: 100%;}
.ico-quote{position: absolute;margin-top: -12px;margin-left: -10px;}
.quote-txt{width: 100%; border-radius: 10px; padding: 7px 7px 7px 25px;}
.quote-txt .quote{font-size: 1.8rem; font-weight: bold;}
.quote-txt .autor{font-size: 1.4rem; padding-left: 10px;}

.quote-onlightbg{background-color: var(--quote-onlightbg);}
.quote-ondarkbg{background-color: var(--quote-ondarkbg);}

.res-cont-head{background: url("../../images/services/bg-curva-top.svg") top left no-repeat; height: 50px; background-size: cover;}
@media(min-width: 1200px) {
    .services-head {height: 542px}
    .head-txt {width: 60%;float: left;margin-top: 100px;}
    .head-txt-detail {width: 100%;}
    .head-txt-detail h1{font-size:42px}
    .head-txt p,
    .head-txt-detail p {font-size: 1.40rem;line-height: 2rem;}
    .head-img {float: right;margin-top: 85px;}
    .head-img-detail {margin-top: 135px;}
    .serv-box {width: 350px;height: 295px;}

    .portfolio-detail .head-subttl{font-size: 32px;}
  }


@media(max-width: 1650px) {.about-midd{padding-right: 30px;}}

@media(max-width: 1500px) {
    .about-midd{padding-right: 0px;}
    .about-container{max-width: auto; margin: 40px auto;}
    .about-left, .about-right{display: none}

    .about-up{display: block;}
    .about-up img{width: 100%;  max-width: 300px;}
    .about-down{display: block; width: 260px; margin: 40px auto 0; text-align: center;}
}

@media(max-width: 1199px) {
    .serv-box {justify-content: space-between;padding: 10px;}
    .serv-box-desc { overflow: hidden;text-overflow: ellipsis;}
    .supportandcons {flex-direction: column-reverse;}

    .head-txt h1 {margin-top: 0px;}
    .head-txt {width: 60%;float: left;margin-top: 40px;margin-right: 40px;}
    .head-img {margin-top: 85px;}
    .head-txt-detail {width: 100%;max-width: 720px;text-align: center;align-items: center;}
    .head-img-detail {width: 100%;max-width: 350px;height: auto;}

    .portfolio-detail{height: 400px;}

}

@media(max-width: 1023px) {
    .serv-box-desc {display: none;}
    .project {height: auto;width: 100%;max-width: 570px;margin: 0 auto 25px;flex-direction: column;}
    .proj-img img {width: 100%;height: auto;}

    .services-head { background-position: top center; }
    .head-txt {width: 100%;float: inherit;margin-top: 20px;margin-right: 0px;}
    .head-img {margin: 55px auto 0;width: auto;max-width: 200px;height: auto;}
    .head-img-detail {margin: 25px auto 0;width: auto;max-width: 290px;height: auto;}

    .portf-head {flex-direction: column;}
    .portf-ttl,
    .port-serv-select,
    .portf-search-cont {
        margin-bottom: 15px;
    }
    .serv-box {width: 100%;height: 200px;padding: 20px;}
    .serv-box-ttl {line-height: 1.2;}

    .res-cont{background-size: 210px;}


    .quote-txt span{display: block; text-align: center;}
}

@media(max-width: 767px) {
  .portfolio-detail{height: auto; padding-bottom: 60px; margin-bottom: 0px !important;}
  .portfolio-detail img{width: 100%;}

  .about-up img{width: 100%;  max-width: 200px;}

    .quote-cont{width: 80%;}
    .quote-txt{padding: 20px;}
    .quote-txt .quote{font-size: 1.4rem; margin-bottom: 10px; text-align: left;}
    .quote-txt .autor{font-size: 1.0rem; padding: 0px;}

    .res-cont {background-size: 160px;background-position: top center;padding-top: 120px;}
}

@media(max-width: 639px) {
    .serv-box {width: 45%;height: 150px;padding: 10px;}
    .serv-box-ttl {line-height: 1.2;}
    .serv-box-ttl {font-size: 1.2rem;}

    .about-up img{width: 100%;  max-width: 130px;}
    .about-down img{width: 100%;  max-width: 150px;}
}


@media(max-width: 380px) {
    .serv-box {width: 100%;height: auto;padding: 10px;}
    .serv-box-ttl {line-height: 1.2;}
    .serv-box-ttl {font-size: 1.2rem;}
    .head-img {margin: 25px auto 0;width: auto;max-width: 150px;height: auto;}
    .head-img-detail {margin: 25px auto 0;width: 100%;max-width: 300px;height: auto;}
    .port-serv-select,
    .portf-search-cont {
        width: 100%;
    }
}

@media(max-width:768px) {
    .support-ninja {
        flex-direction: column-reverse;
        align-items: center;
    }
    .support-ninja-info {
        width: 100%;
    }
    .support-ninja-info .support-ninja-ttl {
        font-size: 26px;
    }
    .bluebg {
        flex-direction: column;
        height: auto;
    }
    .bluebg p {
        font-size: 24px;
    }
}


/* *** UX/UI *** */
.nodesktop {
  display: none
}

.services-head-ux {
  background: #EFEFEF;
  width: 100%
}

.services-head-ux .header {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 250px;
  padding-top: 55px
}

.services-head-ux .header .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.services-head-ux .header .head-txt-detail {
  width: 540px;
  color: var(--text-blue)
}

.services-head-ux .header .head-txt-detail .serv-headico-detail {
  margin-right: 20px
}

.services-head-ux .header .head-txt-detail .serv-headico-detail .st0 {
  fill: var(--text-blue)
}

.services-head-ux .header .head-txt-detail h1 {
  margin-bottom: 10px
}

.services-head-ux .header .head-img-ux {
  max-width: 480px;
  position: absolute;
  margin-left: 660px;
  height: auto
}

.head-maincontainer {
  background: #fff url("../../images/services/ux/bg-header.png") center -50px no-repeat;
  background-size: cover;
  height: calc(100% - 240px);
  padding-top: 60px;
  min-height: 925px
}

.head-maincontainer .breadcrumbs a {
  color: #fff
}

.head-maincontainer .breadcrumbs i::before {
  color: #fff
}

.head-maincontainer .stepsux-container {
  width: 80%;
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 140px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.head-maincontainer .stepsux-container .headstep {
  height: 280px;
  width: 200px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 210px;
  background-position: center -15px;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, border-color 0.15s ease-in-out, display 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, border-color 0.15s ease-in-out, display 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, display 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, display 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out
}

.head-maincontainer .stepsux-container .headstep:hover {
  text-decoration: none
}

.head-maincontainer .stepsux-container .step-discover {
  background-image: url("../../images/services/ux/headstep-1.svg")
}

.head-maincontainer .stepsux-container .step-discover:hover {
  background-image: url("../../images/services/ux/headstep-1-hover.svg")
}

.head-maincontainer .stepsux-container .step-initial {
  background-image: url("../../images/services/ux/headstep-2.svg")
}

.head-maincontainer .stepsux-container .step-initial:hover {
  background-image: url("../../images/services/ux/headstep-2-hover.svg")
}

.head-maincontainer .stepsux-container .step-fine {
  background-image: url("../../images/services/ux/headstep-3.svg")
}

.head-maincontainer .stepsux-container .step-fine:hover {
  background-image: url("../../images/services/ux/headstep-3-hover.svg")
}

.head-maincontainer .stepsux-container .step-implement {
  background-image: url("../../images/services/ux/headstep-4.svg")
}

.head-maincontainer .stepsux-container .step-implement:hover {
  background-image: url("../../images/services/ux/headstep-4-hover.svg")
}

@-webkit-keyframes ctanimation {
  0% {
      right: 20px;
      bottom: -240px;
      visibility: inherit
  }
}

@keyframes ctanimation {
  0% {
      right: 20px;
      bottom: -240px;
      visibility: inherit
  }
}

.mini-contact-hidden {
  bottom: -110px !important;
  position: fixed !important
}

.mini-contact-animation {
  -webkit-animation-name: ctanimation;
  animation-name: ctanimation;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s
}

#mini-contact-pop {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.94);
  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80%;
  max-width: 990px;
  height: 100px;
  max-width: 990px;
  margin: 0 auto;
  position: relative;
  bottom: 20px
}

#mini-contact-pop img {
  width: 80px;
  height: auto
}

#mini-contact-pop div h2 {
  margin: 0px;
  font-size: 19px;
  color: #5C5C5C
}

#mini-contact-pop div p {
  margin: 0px;
  font-size: 14px
}

#mini-contact-pop .btn-primary {
  font-weight: bold;
  padding: 5px 20px;
  width: 150px;
  text-align: center;
  margin: 10px 0 0
}

#mini-contact-pop .btn-primary:hover {
  text-decoration: none
}

.discovery-container {
  background: url("../../images/services/ux/bg-discovery.svg") center bottom no-repeat;
  background-size: 100%;
  min-height: 860px
}

.discovery-container .discovery {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch
}

.discovery-container .discovery .discover-img {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.discovery-container .discovery .discovery-steps {
  color: var(--text-blue) !important;
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.discovery-container .discovery .discovery-steps .ttl {
  font-size: 42px;
  margin-top: 0px;
  font-weight: bold;
  border-bottom: 1px solid var(--text-blue);
  width: 100%;
  line-height: inherit;
  color: var(--text-blue) !important
}

.discovery-container .discovery .discovery-steps div {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px
}

.discovery-container .discovery .discovery-steps div p {
  margin: 0px;
  font-size: 22px;
  line-height: 1;
  font-weight: bold;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.discovery-container .discovery .discovery-steps .disc-define {
  width: 260px
}

.discovery-container .discovery .discovery-steps .disc-understand {
  width: 216px
}

.discovery-container .discovery .discovery-steps .disc-define i {
  -webkit-mask: url("../../images/services/ux/ico-define.svg") no-repeat center;
  mask: url("../../images/services/ux/ico-define.svg") no-repeat center;
  -webkit-mask-size: 70px;
  mask-size: 70px;
  background-color: var(--text-blue);
  display: block;
  width: 70px;
  height: 70px;
  margin-right: 15px
}

.discovery-container .discovery .discovery-steps .disc-understand i {
  -webkit-mask: url("../../images/services/ux/ico-understand.svg") no-repeat center;
  mask: url("../../images/services/ux/ico-understand.svg") no-repeat center;
  -webkit-mask-size: 70px;
  mask-size: 70px;
  background-color: var(--text-blue);
  display: block;
  width: 70px;
  height: 70px;
  margin-right: 15px
}

.initialdesign-container {
  background: #0F347E;
  background-size: cover;
  background-position-x: center -50%
}

.initialdesign-container .initialcont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.initialdesign-container .initialcont .img {
  background: url("../../images/services/ux/gradient.svg") no-repeat center center;
  background-size: cover;
  width: 50%;
  margin: 100px 0 150px
}

.initialdesign-container .initialcont .img img {
  width: 80%;
  margin: 0 auto
}

.initialdesign-container .initialcont .initial-steps {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.initialdesign-container .initialcont .initial-steps h2 {
  color: #fff;
  margin: 0px;
  line-height: inherit;
  font-size: 42px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}

.initialdesign-container .initialcont .initial-steps h2 i {
  -webkit-mask: url("../../images/services/ux/ico-initial-design.svg") no-repeat center;
  mask: url("../../images/services/ux/ico-initial-design.svg") no-repeat center;
  -webkit-mask-size: 60px;
  mask-size: 60px;
  background-color: #fff;
  display: block;
  width: 70px;
  height: 70px;
  margin-right: 15px
}

.initialdesign-container .initialcont .initial-steps ul {
  padding-left: 85px;
  margin: 0px;
  list-style: none;
  width: 460px
}

.initialdesign-container .initialcont .initial-steps ul li {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 35px;
  line-height: 1.2
}

.designfine-container {
  background: #fff url("../../images/services/ux/bg-curves-topdesignfinetuning.svg") top center no-repeat;
  padding-top: 130px;
  background-size: contain;
}

.designfine-container .container {
  text-align: center
}

.designfine-container .container h2 {
  color: #082766;
  margin: 0px;
  line-height: inherit;
  font-size: 42px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 auto
}

.designfine-container .container h2 i {
  -webkit-mask: url("../../images/services/ux/ico-design-fine-tuning.svg") no-repeat center;
  mask: url("../../images/services/ux/ico-design-fine-tuning.svg") no-repeat center;
  -webkit-mask-size: 70px;
  mask-size: 70px;
  background-color: #082766;
  display: block;
  width: 70px;
  height: 70px;
  margin-right: 15px
}

.designfine-container .container .img {
  margin-top: -50px
}

.designfine-container .container .img img.nomobile {
  max-width: 990px;
  height: auto;
  margin: 0 auto;
  width: 85%
}

.designfine-container .container .img img.nodesktop {
  display: none
}

.implementation-container {
  background: #fff url("../../images/services/ux/img-implementation.png") center left no-repeat;
  background-size: 50%;
  padding-top: 130px;
  height: 1000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.implementation-container .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.implementation-container .container .implementation {
  width: 50%
}

.implementation-container .container .implementation h2 {
  color: #082766;
  margin: 0px;
  line-height: inherit;
  font-size: 42px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 auto
}

.implementation-container .container .implementation h2 i {
  -webkit-mask: url("../../images/services/ux/ico-implementation.svg") no-repeat center;
  mask: url("../../images/services/ux/ico-implementation.svg") no-repeat center;
  -webkit-mask-size: 70px;
  mask-size: 70px;
  background-color: #082766;
  display: block;
  width: 70px;
  height: 70px;
  margin-right: 15px
}

.implementation-container .container .implementation .logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 480px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

@media (max-width: 1200px) {
  .container {
      width: 100%
  }
  .services-head-ux .header .head-img-ux {
      margin-left: initial;
      right: 50px
  }
  .head-maincontainer .stepsux-container {
      width: 90%
  }
  .head-txt-detail h1,
  .head-txt-detail p {
      text-align: left
  }
}

@media (max-width: 992px) {
  .nodesktop {
      display: initial
  }
  .head-img-ux {
      display: none
  }
  .services-head-ux .header {
      height: auto;
      display: initial
  }
  .services-head-ux .header .head-txt-detail {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column
  }
  .services-head-ux .header .head-txt-detail .serv-headico-detail {
      margin-right: 0px
  }
  .services-head-ux .header .head-txt-detail h1 {
      margin-bottom: 10px
  }
  .services-head-ux .header .serv-headtxt-detail {
      text-align: center
  }
  .services-head-ux .header .serv-headtxt-detail h1 {
      text-align: center
  }
  .services-head-ux .header .serv-headtxt-detail p {
      text-align: center;
      max-width: 350px;
      width: 100%
  }
  .services-head-ux .header .head-img-ux {
      max-width: 480px;
      position: absolute;
      margin-left: 660px;
      height: auto
  }
  .head-maincontainer .breadcrumbs {
      padding-top: 15px
  }
  .head-maincontainer .breadcrumbs a {
      font-size: 18px
  }
  .head-maincontainer .stepsux-container {
      width: 100%
  }
  .discovery-container .discovery .discovery-steps .disc-define {
      width: 100%;
      margin: 15px 0 51px
  }
  .discovery-container .discovery .discovery-steps .disc-understand {
      width: 100%
  }
  #mini-contact-pop {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 250px;
      width: 300px
  }
  #mini-contact-pop h2 {
      text-align: center
  }
  #mini-contact-pop p {
      text-align: center
  }
}

@media (max-width: 880px) {
  .discovery-container {
      background: url("../../images/services/ux/bg-discovery-mobile.svg") center bottom no-repeat;
      background-size: 100%;
      padding-bottom: 300px
  }
  .discovery-container .discovery {
      width: 80%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: initial;
      -ms-flex-pack: initial;
      justify-content: initial
  }
  .discovery-container .discovery .discover-img {
      -webkit-box-flex: initial;
      -ms-flex: initial;
      flex: initial
  }
  .discovery-container .discovery .discovery-steps {
      color: var(--text-blue) !important;
      -webkit-box-flex: initial;
      -ms-flex: initial;
      flex: initial;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      max-width: 300px
  }
  .discovery-container .discovery .discovery-steps .ttl {
      font-size: 42px;
      text-align: center;
      margin-top: 0px;
      font-weight: bold;
      border-bottom: 0;
      width: 100%;
      line-height: inherit;
      color: var(--text-blue) !important
  }
  .discovery-container .discovery .discovery-steps div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 18px
  }
  .discovery-container .discovery .discovery-steps div p {
      margin: 0px;
      font-size: 22px;
      line-height: 1;
      font-weight: bold;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex
  }
  .discovery-container .discovery .discovery-steps .disc-define {
      width: 100%
  }
  .discovery-container .discovery .discovery-steps .disc-understand {
      width: 100%
  }
  .initialdesign-container {
      padding: 100px 0
  }
  .initialdesign-container .initialcont {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center
  }
  .initialdesign-container .initialcont .img {
      width: 60%;
      margin: 0 auto 50px;
      text-align: center
  }
  .initialdesign-container .initialcont .img img {
      width: 80%;
      margin: 0 auto
  }
  .initialdesign-container .initialcont .initial-steps {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center
  }
  .initialdesign-container .initialcont .initial-steps h2 {
      margin-bottom: 30px
  }
  .initialdesign-container .initialcont .initial-steps ul {
      padding-left: 0px;
      width: 370px
  }
  .designfine-container {
      padding: 100px 0
  }
  .designfine-container .container .img {
      margin-top: 0px
  }
  .designfine-container .container .img img.nomobile {
      display: none !important
  }
  .designfine-container .container .img img.nodesktop {
      display: block;
      width: 100%;
      max-width: 500px;
      margin: 0 auto
  }
  .implementation-container {
      background: none;
      background-size: 50%;
      padding-top: 130px;
      height: auto;
      display: inherit
  }
  .implementation-container .container {
      display: inherit;
      padding: 0px
  }
  .implementation-container .container .implementation {
      width: 100%
  }
  .implementation-container .container .implementation h2 {
      width: 100%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }
  .implementation-container .container .implementation .logos {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0 20px;
      width: 100%;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between
  }
}

@media (max-width: 768px) {
  .head-img-ux {
      width: 800px !important
  }
  .services-head-ux {
      background: #EFEFEF;
      width: 100%
  }
  .services-head-ux .header {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: auto;
      padding-top: 55px
  }
  .services-head-ux .header .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }
  .services-head-ux .header .head-txt-detail {
      width: 540px;
      color: var(--text-blue)
  }
  .services-head-ux .header .head-txt-detail .serv-headico-detail {
      margin-right: 20px
  }
  .services-head-ux .header .head-img-ux {
      max-width: 480px;
      position: absolute;
      margin-left: 660px;
      height: auto
  }
  .head-maincontainer .stepsux-container {
      width: 100%;
      height: auto;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-top: 30px
  }
  .head-maincontainer .stepsux-container .headstep {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 50%
  }
  .discovery-container {
      padding-top: 300px
  }
  .designfine-container .container .img {
      margin-top: 0px
  }
  .designfine-container .container .img img.nomobile {
      display: none !important
  }
  .designfine-container .container .img img.nodesktop {
      display: block;
      width: 100%;
      max-width: 380px
  }
  .implementation-container .container .implementation .logos {
      max-width: 520px;
      margin: 0 auto
  }
  #mini-contact-pop {
      bottom: -180px
  }
  #mini-contact-pop h2 {
      text-align: center
  }
  #mini-contact-pop p {
      text-align: center
  }
}

@media (max-width: 600px) {
  .discovery-container .discovery .discovery-steps .disc-define {
      width: 100%
  }
  .discovery-container .discovery .discovery-steps .disc-define i {
      width: 50px;
      height: 50px;
      -webkit-mask-size: 40px;
      mask-size: 40px;
      margin-right: 5px
  }
  .discovery-container .discovery .discovery-steps .disc-understand {
      width: 100%
  }
  .discovery-container .discovery .discovery-steps .disc-understand i {
      width: 50px;
      height: 50px;
      -webkit-mask-size: 40px;
      mask-size: 40px;
      margin-right: 5px
  }
  .initialdesign-container .initialcont .img {
      width: 100%
  }
  .initialdesign-container .initialcont .initial-steps {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center
  }
  .initialdesign-container .initialcont .initial-steps h2 {
      margin-bottom: 30px;
      font-size: 32px;
      width: 300px
  }
  .initialdesign-container .initialcont .initial-steps h2 i {
      width: 50px;
      height: 50px;
      -webkit-mask-size: 40px;
      mask-size: 40px;
      margin-right: 5px
  }
  .initialdesign-container .initialcont .initial-steps ul {
      padding-left: 0px;
      width: 290px
  }
  .initialdesign-container .initialcont .initial-steps ul li {
      font-size: 22px
  }
  .designfine-container {
      padding: 100px 0
  }
  .designfine-container .container h2 {
      font-size: 32px
  }
  .designfine-container .container h2 i {
      width: 50px;
      height: 50px;
      -webkit-mask-size: 40px;
      mask-size: 40px;
      margin-right: 5px
  }
  .designfine-container .container .img {
      margin-top: 0px
  }
  .designfine-container .container .img img.nomobile {
      display: none !important
  }
  .designfine-container .container .img img.nodesktop {
      max-width: 300px
  }
  .implementation-container {
      padding-top: 50px
  }
  .implementation-container .container {
      display: inherit;
      padding: 0px
  }
  .implementation-container .container .implementation {
      width: 100%
  }
  .implementation-container .container .implementation h2 {
      font-size: 32px
  }
  .implementation-container .container .implementation h2 i {
      width: 50px;
      height: 50px;
      -webkit-mask-size: 40px;
      mask-size: 40px;
      margin-right: 5px
  }
  .implementation-container .container .implementation .logos {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }
  .implementation-container .container .implementation .logos img {
      width: 20%
  }

  .bg-coldbox-contentbox {
    min-height: 340px;
  }
}
/* *** END UX/UI *** */

@mixin svgmask-bg($ico-url, $size, $width, $height, $display, $position, $bgcolor) {
  mask: url($ico-url) no-repeat $position;
  mask-size: $size;
  -webkit-mask: url($ico-url) no-repeat $position;
  -webkit-mask-size: $size;
   display: $display;
  width: $width;
  height: $height;
  background-color: $bgcolor;
}

.div-web-development {
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-web-development.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-uxui{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-uxui.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-mobile-app{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-mobapp.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-legacy-migration{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-legacy-migration.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-quality-assurance{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/qa/ico-qa.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-support{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-support.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-BI{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-bi.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-infrastructure{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-devops.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-security{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-security.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-training{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-expert-training.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-government{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-government.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-hosting{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-web-hosting.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

.div-dba-consulting{
  .icon{
      @include svgmask-bg(
          $ico-url: '../../images/services/overviewIcons/ico-dba.svg',
          $size: 100%,
          $width: 80px,
          $height: 80px,
          $display: block,
          $position: center center,
          $bgcolor: var(--text-blue) );
          margin: 0px;
  }
  &:hover {
    .icon{ background-color: #fff !important; }
      color: #fff;
  }
}

#special-dropdown .panel-heading {
  border: 0 !important;
  border-radius: 10px;
  padding: 0px !important;
  }

  .accordion-arrow {float: right;
      margin-top: 3px;
      transform: rotate(180deg);
      transition:transform 0.3s
  }
   .collapsed .accordion-arrow {
    float: right;
      margin-top: 3px;
      transform: rotate(0deg);
      transition:transform 0.3s}

  #special-dropdown a.list-group-item {
      position: relative;
      display: block;
      padding: 13px;
      background-color: #F4F7FF;
      border: 1px solid transparent;
      border-radius: 10px;
      color: #464646;
      font-size: 18px;
  }
  #special-dropdown a[aria-expanded="true"] {
      font-weight: bold;
      color: #082766;
  }


.benefits{
  background-color: #F4F7FF;
  border: 1px solid #DBE1EF;
  border-radius: 0px 0px 10px 10px;
  border-radius: 10px !important;
}
.panel-body{margin:0 15px 15px; border: 0px !important; background: #fff;}

.qa-wave-top {
  background: url("../../images/services/qa/wave-top.svg") center bottom no-repeat;
  min-height: 400px
}

.bg-qa-testing{
  background-color: #082766;
}

.policy-head {
  background-image: url("../../images/services/bg-services-head.svg");
  background-size: cover;
  background-position: bottom;
}
