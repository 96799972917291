.btn-primary, .btn-success{
    --btn-border-radius:7px;
    --btn-bg: #{$primary};
    --btn-color: #fff;
    --btn-border-color: #{$primary};
    --btn-border-width: 2px;
    --btn-margin: 10px;
    --btn-font-weight: 500;
    --btn-text-transform: uppercase;
    --btn-active-bg: #fff;
    --btn-active-color: #{$primary};
    --btn-hover-bg: #fff;
    --btn-hover-color: #{$primary};

    border: var(--btn-border-width) solid var(--btn-border-color);
    border-radius: var( --btn-border-radius );
    background-color: var(--btn-bg);
    color: var(--btn-color);
    font-weight: var( --btn-font-weight);
    text-transform: var(--btn-text-transform);
    margin: var(--btn-margin);

    &:hover, &:focus,  &:active:hover{
        background-color: var(--btn-hover-bg);
        color:var(--btn-hover-color);
        border: var(--btn-border-width) solid var(--btn-border-color);
    }
    &:active, &.active{
        background-color: var(--btn-active-bg);
        color: var( --btn-active-color);
        border: var(--btn-border-width) solid var(--btn-border-color);
    }
}


.btn-secondary, .btn-info{
    border: 2px solid $secondary;
    border-radius: 7px;
    background-color: $secondary;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    margin: 10px;
    &:hover{
        background-color: #fff;
        color:$secondary;
        border: 2px solid $secondary;
    }
    &:active{
        background-color: #fff;
        color:$secondary;
        border: 2px solid $secondary;
    }
    &:focus{
        border: 2px solid $secondary;
        background-color: $secondary;
        color: #fff;
    }
    &:active:hover{
        background-color: #fff;
        color: $secondary;
        border: 2px solid $secondary;
    }
}

.btn {
	font-size: 18px;
	padding: 10px 20px;
}

.btn-couponcode{
    margin: 0px!important;
}

.btn-ortus-outline{
    border: 2px solid $bright-green;
}

.btn-ortus-outline:hover {
    border: 2px solid $bright-blue;
    background-color: $bright-blue;
}