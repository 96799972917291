:root {
    --text-blue: #0B3188;
    --ortusgreen: #8FC73E;
}

.service-mobileapp-header {
    background: url(../../../images/services/mobileapp/bghead.svg) no-repeat top right;
    margin-bottom: 100px;
    background-size: 60%;
    .head-info-container {
        display: flex;
        justify-content: space-between;
        .head-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .mobileapp-ttl {
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                svg {
                    margin-right: 30px;
                }
                .txt {
                    color: var(--text-blue);
                    h1 {
                        font-weight: 700;
                        margin: 0 0 5px;
                    }
                    p {
                        max-width: 414px;
                        margin: 0px;
                        font-size: 18px;
                    }
                }
            }
        }
        .mobileapp-img {
            img {
                width: 530px;
                height: auto;
            }
        }
    }
}

.mobapp-stats-waveup {
    background: url(../../../images/services/mobileapp/wave-stats-top.svg) center no-repeat;
    height: 85px;
}

.mobapp-stats-wavedown {
    background: url(../../../images/services/mobileapp/wave-stats-bottom.svg) center no-repeat;
    height: 118px;
    background-size: cover;
}

.stats-container {
    background: var(--text-blue);
    padding: 40px 20px;
    .container {
        justify-content: space-between;
    }
    .stats-col {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: space-between;
    }
    .stats {
        // margin: 15px 0;
        display: inline-flex;
        align-items: center;
        .stats-data {
            width: 80px;
            height: 80px;
            background: url(../../../images/services/mobileapp/circle-stats.svg) center no-repeat;
            background-size: contain;
            color: #fff;
            font-weight: bold;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            .big-txt {
                font-size: 20px;
            }
            .small-txt {
                font-size: 14px;
            }
        }
        p {
            width: calc(100% - 80px);
            padding-left: 15px;
            color: #fff;
            font-size: 18px;
            margin: 0px;
            padding-right: 30px;
            a {
                color: var(--ortusgreen);
                &:hover {
                    color: #3B95FF;
                }
            }
        }
    }
}

.why-webapp-container {
    padding: 300px 0px 100px;
    display: flex;
    .whywebapp {
        display: flex;
        align-self: center;
        width: 50%;
        flex-direction: column;
        //max-width: 700px;
        h2 {
            color: var(--text-blue);
            font-size: 2.5rem;
        }
        .listcheck {
            list-style-image: url(../../../images/services/mobileapp/ico-check.svg);
            li {
                margin-bottom: 35px;
                strong {
                    font-size: 24px;
                    color: var(--text-blue);
                }
                p {
                    margin-top: 10px;
                    font-size: 18px;
                }
            }
        }
        .listbullet {
            font-size: 18px;
            padding-left: 30px;
            list-style-image: url(../../../images/services/mobileapp/ico-bullet.svg);
            li {
                margin-bottom: 15px;
            }
        }
    }
}

@media(max-width:1200px) {
    .service-mobileapp-header {
        background-size: 60%;
    }
    .mobileapp-options-container {
        .options-container {
            .option {
                flex-direction: column;
                justify-content: space-between;
                padding: 20px;
                .txt {
                    height: 90px;
                }
            }
        }
    }
}

@media(max-width:1080px) {}

@media(max-width:992px) {
    .service-mobileapp-header {
        background-size: contain;
        .head-info-container {
            flex-direction: column-reverse;
            align-items: center;
            padding-top: 60px;
            .mobileapp-ttl {
                margin: 50px auto;
            }
            .mobileapp-img {
                img {
                    width: 530px !important;
                    height: auto;
                }
            }
        }
    }
    .stats-container {
        .container {
            flex-direction: column;
        }
        .stats-col {
            width: 100%;
            margin-bottom: 30px;
        }
    }
}

@media(max-width:880px) {
    .why-webapp-container {
        padding: 200px 0px 100px;
        display: flex;
        flex-direction: column-reverse;
        .whywenapp-img {
            width: 100%;
        }
        .whywebapp {
            width: 100%;
            padding: 0 20px;
            max-width: 700px;
            align-self: center;
        }
    }
}

@media(max-width:768px) {
    .service-mobileapp-header {
        background-size: 150%;
        background-position: top center;
    }
    .stats-container {
        padding-left: 0;
        padding-right: 0;
        .container {
            flex-direction: column;
            gap: 50px;
        }
        .stats-col {
            gap: 50px;
            .stats {
                flex-direction: column;
                .stats-data {
                    width: 125px;
                    height: 125px;
                    margin-bottom: 20px;
                    .big-txt {
                        font-size: 30px;
                    }
                    .small-txt {
                        font-size: 18px;
                    }
                }
                p {
                    padding: 0;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

@media(max-width:600px) {}