.products-head {
    background-image: url("../../../images/products/coldbox/bg-head.png");
    background-size: cover;
    background-position: bottom;
}

.products-universe {
    background: #0F347E url("../../../images/products/coldbox/wave-cbuniverse-top.svg") top center no-repeat;
    margin-bottom: 0 !important;
}

.bg-universe-buttom {
    background: url("../../../images/products/coldbox/wave-cbuniverse-bottom.svg") top center no-repeat;
    padding-top: 150px;
    margin-top: -30px !important;
}

 #coldboxId .btn-primary {
    margin: 0px;
}

#coldboxId h1, h2, h3{
    margin: 0px;
}

.support-box {
    background: #EFF4FF;
    text-decoration: none;
}

.support-box:hover {
    background: #3B95FF;
    text-decoration: none;
}

.bounce {
    transform: translateY(0);
    transition: transform 0.2s ease-in-out;
}

.support-box:hover .bounce {
    transform: translateY(-10px);
    transition: transform 0.2s ease-in-out;
}

.support-box .text {
    height: 35%;
    background-position: center 2px !important;
}

.support .text {
    color: #0F347E;
    line-height: 1;
}

.support:hover .text {
    color: #fff !important;
}

.or-community .text {
    background: url("../../../images/partials/ortus-community-text.svg") center no-repeat;
}

.or-community:hover .text {
    background: url("../../../images/partials/ortus-community-text-white.svg") center no-repeat;
}

.box-team .text {
    background: url("../../../images/partials/box-team-text.svg") center no-repeat;
}

.box-team:hover .text {
    background: url("../../../images/partials/box-team-text-white.svg") center no-repeat;
}

.btn-learn-cfcasts {
    background: url("../../../images/products/coldbox/img-learn-cfcasts.svg") center no-repeat;
    background-size: 60% !important;
    border-radius: 7px;
    background-color: #fff;
}

.btn-learn-cfcasts:hover {
    background-color: #343434 !important;
    border: 2px solid #343434;
    background: url("../../../images/products/coldbox/learn-cfcasts-white.svg") center no-repeat;
}

.text-blue {
    color: #0F347E;
}

.bg-books {
    background-image: url("../../../images/books/bg_otherbooks.jpg");
    background-color: #181F32;
}

.img-book {
    background-color: #242D4A;
}

.img-book:hover {
    background-image: url("../../../images/books/bg_hover_books.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.text-green-coldbox {
    color: $bright-green !important;
}

.text-ico-copy {
    color: #B4B4B4;
}

#coldboxId a:hover,
a:focus {
    color: $bright-green; 
}

#coldboxId a:hover,
a:focus {
    color: $bright-green; 
    text-decoration: none !important;
}

#coldboxId .syntaxhighlighter .line.alt1 {
    background-color: #fff !important;
}

#coldboxId .syntaxhighlighter .line.alt2 {
    background-color: #fff !important;
}

#coldboxId .syntaxhighlighter {
    background-color: #fff !important;
}