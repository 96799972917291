/***************************************** BOOSTRAP OVERRIDES ******************************************/
.tooltip {
    font-size: 11px;
}


/***************************************** GLOBAL ELEMENTS ******************************************/

html{
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background: #fff;
}

.text-center {
    img {
      display:initial;
    }
 }

.iframe-size {
    width: 100%;
    height: 350px;
}

.lead {
    // font-size: 16px;
    line-height: 1.625;
}

body.print {
    padding-top: 0px;
}

a {
    color: #007faa;
    text-decoration: none;
}

a:hover,
a:focus {
    color: $bright-green;
}
a:focus {
    outline: 2px auto $bright-green;
    outline-offset: 2px;
}
div.syntaxhighlighter div.container:after,
div.syntaxhighlighter div.container:before {
    display: block !important;
}

.flex-container {
    display: flex;
}

.flex-grow-item {
    flex-grow: 1;
}
/***************************************** HEADER  ******************************************/

header {
    background-color: #eee;
    position: relative;
    width: 100%;
    z-index: 100;
}
.header-top {
    background: $primary;
    color: #fff;
    padding: 20px 0px;
    width: 100%;

    a {
        color: #fff;
    }
}

.breadcrumbs {
    margin-top: 15px;
    font-size: 14px;
    font-style: italic;
}
.header-top .caption {
    margin-top: 15px;
}

.header-top .page-title {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
}

.font-openSans {
    font-family: 'Open Sans', sans-serif;
}

/***************************************** MAIN Content ******************************************/
.page, .blog, .landing {
    .main > .container {
        padding-bottom: 80px;
    }
    .main > .container-fluid {
        padding-bottom: 80px;
    }
}
.main {
    padding: 0;
    margin: 0 auto;
}

.main section {
    margin-top: 50px;
    margin-bottom: 50px;
}

.main section .page-header {
    margin-bottom: 30px;
}

.main .service-blocks {
    background-color: #f5f5f5;
    height: 335px;
}

.main .product-blocks {
    height: 300px;
}

.main .headline {
    font-weight: bold;
    font-size: 40px
}

.img-client {
    float: left;
    margin: 10px;
    border: 1px solid #ddd;
}

.img-client-nofloat {
    margin: 10px;
    border: 1px solid #ddd;
}


/***************************************** UNIQUE IDS ******************************************/

#home{
    #testimonials{
        background-color: #fafbf1;
        padding: 120px 0;
        margin: 0;

        .cb-widget-csgrid{
            margin: 25px 0;
        }
        .pic{
            margin: 25px;
            img{
                border-radius: 50%;
                max-height: 200px;
                margin: auto;
                border: $bright-green 5px solid;
            }
        }
        h3 {
            display: none;
        }
        .desc{
            padding: 0 15px;
        }
        .quote{
            display:block;
            padding: 0 5px;
            &:before, &:after{
                content: '"';
                font-family: 'Bungee', cursive;
                font-size: 2rem;
                display:block;
                line-height: 1em;
            }
            &:after{
                text-align: right;
            }

        }
        .name{
            color: $primary;
            font-size: 1.3em;
            display: block;
        }
    }
}
#services-inquire {
    height: 300px;
}

#features{
	border: 4px solid #72BD38;
	border-radius:12px;
	padding: 24px;
	background-color: #f5f5f5;
}
#features h2{
	color:#72BD38;
	font-weight:bold;
}
#features h1{
 margin-top: -35px;
}
#main-content-sidebar {
    h2 {
        font-size: 1.8rem;
        color: $grey;
    }
}

/***************************************** NEWSLETTER ******************************************/

.newsletter {
    margin-bottom: 100px;
    h2{
        font-size: 18px;
        color: #757575;
        font-weight: 300;
        text-align: left;
    }
    input {
        border-radius: 5px;
        border: 1px grey solid;
        padding: 5px;
        color: grey;
		width: 100%;
    }
    button {
        width: 20%;
        margin: 0;
        &:hover {
            background-color: #fff;
        }
    }
}

#mc_embed_signup_widget{
	.form-control{
		width: 100%;
	}
}

/***************************************** FOOTER ******************************************/
.footer-main{
    position: relative;
    background-color:#000002;
    color: #fff;
	margin-top: 100px;

    h2 {
        font-size: 2rem;
        color: #8fc73e;
        text-transform: uppercase;
    }

    .footer-logo{
        margin-bottom: 25px;
    }
    .foo-content{
        position: relative;
        margin-top: 70px;
        margin-bottom: 70px;
        .first-column{
            .contact-ways{
                text-align: left;
                display: inline-block;
                i{
                    margin: 0 10px;
                }
            }
            .patreon{
                margin-top:25px;
            }
        }
        .second-column{
            .newsletter {

                input {
                    border-width: 0;
                }
                button {
                    &:hover {
                        background-color: #101010;
                    }
                }
            }
            .menu-foo{
                text-align: left;
                display: inline-block;
                line-height: 3rem;
                .title-foo a{
                    list-style-type: none;
                    color: $bright-green !important;
                }
                ul, li, li a, ul a, li .title-foo{
                    list-style-type: none;
                }
            }
        }
        .third-column{
            text-align: left;
            padding: 0 30px;
            h3{
                font-size: 16px;
            }
            a{
                color: $bright-green!important;
                text-transform: uppercase;
                font-size: 12px;
            }
        }
        a{
            color: #fff;
            &:hover{
                color: $bright-green;
            }
            i{
                margin: 0 10px;
            }
        }
        .footer-copyright{
            margin-top: 50px;
        }
    }
}

.footer-main::before{
    content: "";
    display: inline-block;
    background: url("../../images/moon-footer-fadeout.svg") repeat-x left center;
    -webkit-background-size: auto;
    -moz-background-size: auto;
    -o-background-size: auto;
    background-size: contain;
    top: -170px;
    position: absolute;
    height: 250px;
    width: 100%;

    @media (min-width: 769px){
        top: -200px;
    }
}

/***************************************** UTILITY CLASSES ******************************************/

.inline {
    display: inline;
    margin: 5px 0px 5px 0px;
}

.textRight {
    text-align: right !important;
}

.textLeft {
    text-align: left !important;
}

.textCenter {
    text-align: center !important;
}

.center {
    text-align: center !important;
    margin: auto !important;
}

.padding24 {
    padding: 24px;
}

.padding10 {
    padding: 10px;
}

.margin24 {
    margin: 24px;
}
.padding75 {
    padding: 75px;
}

.margin10 {
    margin: 10px;
}

.padding5 {
    padding: 5px;
}

.margin5 {
    margin: 5px;
}

.margintop15{
    margin-top: 15px;
}

.margintop25{
    margin-top: 25px;
}

.margintop30{
    margin-top: 30px;
}

// To Be Deleted after replacing with text-white
.textWhite {
    color: #fff;
}

.textBlack {
    color: black;
}

.textOrtus {
    color: $bright-green;
}

.btn-round {
    border: 2px solid $bright-green;
    border-radius: 50%;
    padding: 2px 6px;
    background-color: #CEEFB3;
}

.btn-round i {
    color: $bright-green;
}

.meta {
    font-size: 0.85em;
    font-style: italic;
}

.coldbox-blue {
    color: #4CAADD;
}

.contentbox-green {
    color: $bright-green;
}
.overlay {
    background-color: black;
    opacity: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

/** ============================== PAGING TABS =============================== **/

.pagingTabs {
    text-align: center;
    margin: 0px 20px 0px 0px;
}

.pagingTabs a {
    color: #000;
    padding: 2px 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-left: 2px;
}

.pagingTabs a:hover {
    color: #000;
    background-color: #FCF9C3;
    border: 1px solid black;
}

.pagingTabs a.selected {
    color: #000;
    background-color: #FCF9C3;
    border: 1px solid #AF3D15;
}

.pagingTabsCarrousel {
    margin-top: 5px;
}


/** ============================== CODEXWIKI =============================== **/

#toc,
#sidebar-subpages-content {
    font-size: 14px;
}

#toc ul {
    margin-left: 0px;
    padding-left: 12px;
}

#sidebar-subpages-content ul {
    padding-left: 15px;
}

#sidebar-subpages-content li.active>a {
    font-weight: bold;
    color: #029acf;
}

/** ================================ MAIL CHIMP ============================= **/
#mc_embed_signup {
    background:#fff;
    clear:left;
    font:14px Helvetica,Arial,sans-serif;
}

/***************************************** MEDIA QUERIES ******************************************/
@media only screen and (min-width: 1200px) {
    #ortus-logo-main{
        max-width: 250px;
    }
}
/** for bigger screens bwetween 991 and 1199 **/
@media only screen and (max-width: 1199px) and (min-width: 1024px) {
    #ortus-logo-main{
        max-width: 200px;
    }
}
@media only screen and (max-width: 1199px) and (min-width: 991px) {
    #stats {
        margin-top: -133px!important;
    }
}
/** tablet medium weird middle point **/
@media only screen and (max-width: 991px) and (min-width: 768px) {
    #searchForm {
        display: none;
    }
    #ortus-logo-main{
        max-width: 230px;
    }
}
@media only screen and (min-width: 768px) {
    html {
        height: 100%;
    }
    #home{
        height: 100%;

        #app{
            height: inherit;
        }
        .has-admin-bar {
            .stage {
               height: calc(100vh - 55px);
            }
        }
        .stage {
            height: auto;
            position: relative;
        }
/*         header {
            position: absolute;
            bottom: 0;
        } */
        header.sticky {
            position: fixed;
            top: 0;
            bottom: auto;
            width: 100%;
        }
    }
    header.sticky {
        position: fixed;
        top: 0;
        bottom: auto;
        width: 100%;
        box-shadow: 1px 1px 4px rgba( 0,0,0,0.2 );
    }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .main .service-blocks {
        height: 250px;
    }
    .main .product-blocks {
        height: 250px;
    }
}


/** iPhone to tablet small widths **/

@media only screen and (max-width:767px) {
    #navbar-main #searchForm {
        padding: 0px;
        margin: 0px;
    }
    #navbar-main #searchForm #q {
        width: 95%;
        margin: 10px auto;
    }
    .main .service-blocks {
        height: 275px;
    }
    .main .product-blocks {
        height: 275px;
    }
}


/** iphone size only **/

@media only screen and (max-width:480px) {
    .videos {
        width: 90%;
    }
    #navbar-main #searchForm #q {
        width: 98%;
    }
    .header-top #caption-holder {
        display: none;
    }
    #footer .touch {
        padding-top: 20px;
        float: left;
    }
}

/** ================================ LAST ITB VIDEOS ============================= **/

.carousel-wrapper{
	height:400px;
	position:relative;
	width:100%;
	margin:0 auto;
}

.carousel-item{
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	padding:25px 50px;
	opacity:0;
	transition: all 0.5s ease-in-out;

	display: flex;
	align-items: baseline;
	justify-content: center;
}
.arrow{
	border: solid #000;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 12px;
}
.arrow-prev{
	left:-2%;
	position:absolute;
	top:50%;
	transform:translateY(-50%) rotate(135deg);
}
.arrow-next{
	right: -2%;
	position:absolute;
	top:50%;
	transform:translateY(-50%) rotate(-45deg);
}

.light{
color:white;
}

@media (max-width: 480px) {
	.arrow, .light .arrow {
		background-size: 10px;
		background-position: 10px 50%;
	}
}


/*Select every element*/
[id^="item"] {
	display: none;
}

*:target ~ .item-1 {
	opacity: 0;
}

#item-1:target ~ .item-1 {
	opacity: 1;
}

#item-2:target ~ .item-2, #item-3:target ~ .item-3 {
	z-index: 3;
	opacity: 1;
}


.price {
	color: #fff;
	font-size: 31px;
}

.btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

/** ================================ GLOBAL PRINT BARS ============================= **/
#content-printbar{
	#btn-print-mode{
		font-size: 12px;
		padding: 5px 10px;
	}
}

#blog-printbar{
	.btn{
		font-size: 12px;
		padding: 5px 10px;
	}
}


/** ================================ GLOBAL ALERTS ============================= **/

.alert {

	margin-bottom: 0px;
	min-height: 80px;
	font-size: 20px;
	padding-top:23px;

	.close{
		font-size: 40px;
		margin-left: 5px;
	}

	i{
		margin-right: 12px;
	}

	h1 {
		font-size: 30px !important;
		color: #30C6EF !important;
	}

	h2 {
		font-size: 24px !important;
		color: #30C6EF !important;
	}
}

/** ================================ OUR PARTNERS ============================= **/

#our-partners > .col-sm-2{
    display: grid;
    align-content: center;
    justify-content: center;
    height: 125px;
}

/** Spreadshirt Layouts **/
#sprd-main {
    font-size: 15px !important;
}
.fade-enter-active {
    animation: fadeIn .5s;
}
.fade-leave-to {
    animation: fadeOut .5s;
}

.apply-btn-container {
    background-color: #163e51;
    color: white;
    border-radius: 0px 7px 7px 0px;
    width: 27%;
    padding: 0px !important;

    &:hover {
        background-color: #fff;
        color: #163e51;
        border: 2px solid #163e51;
    }
}

.apply-btn {
    padding: 12px 12px;
    font-size: 15px;
}

#card-element {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
}

.cb-list-bullet {
    list-style: none;
}

.cb-list-bullet li::before {
    content: "\2022";
    color: $bright-green;
    display: inline-block;
    width: 1em;
    margin-left: -0.9em;
    font-weight: bold;
    font-size:1.5rem;
}
