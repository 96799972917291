.bg-testbox-head {
    background-image: url("../../../images/products/testbox/bg-head.png");
    background-size: cover;
    height: 450px;
}

#syntax .nav-tabs > li > a:hover {
    border-color: transparent !important;
}

#syntax .nav > li > a:hover, .nav > li > a:focus{
    background-color: transparent !important;
}

#syntax .nav-tabs > li { 
    @apply tw-px-10 tw-font-bold tw-rounded-t-lg;
    @apply tw-bg-indigo-100 tw-text-gray-700 ;
    
    &.active { 
        background-color: #0F347E !important;
        @apply tw-bg-none tw-text-white;
    }

    &.active a { 
        background-color: transparent !important;
        border: none !important;
        color: #fff;
    }
}

#syntax .nav-tabs {
    border-bottom: none;
}

.got-questions-partials::before{
    background: url("../../../images/partials/waveup-darkBlue.svg") top center no-repeat;
    background-size: contain;
}

.got-questions-partials {
    background: #082766;

    .pj-txt {
        max-width: 600px;
        a {
            color: var(--ortusgreen);
            &:hover {
                color: #00dee6;
                text-decoration: none;
            }
        }
    }
}

#textBox-subMenu .list-group-item {
    border-left: none;
    border-right: none;
    @apply tw-border tw-border-t tw-border-b tw-border-gray-200 tw-text-gray-400 tw-text-sm tw-font-bold;
}

#bg-more-information{
    background-color: #0F347E;
}

.more-info-card {
    &:hover {
        background-color: #082766;
    }
    &:hover span {
        @apply tw-text-white;
    }
}

.more-info-sponsor:hover img {
    content: url("../../../images/products/testbox/ico-sponsor-white.svg");
}

.more-info-docs:hover img {
    content: url("../../../images/products/testbox/ico-documentation-white.svg");
}

.more-info-api:hover img {
    content: url("../../../images/products/testbox/ico-api-white.svg");
}

.more-info-source:hover img {
    content: url("../../../images/products/testbox/ico-sourcecode-white.svg");
}

.more-info-issues:hover img {
    content: url("../../../images/products/testbox/ico-issuetracker-white.svg");
}

.more-info-help:hover img {
    content: url("../../../images/products/testbox/ico-helpforum-white.svg");
}