#buy {

    .margin-top {
        margin-top: 6.5%;
    }
}

#faq-accordion {
    .list-group-item {
        background-color: #fcf8e3 !important;
        border-color: transparent !important;
        padding: 5px 10px !important;
    }

    .list-group-item:hover {
        background-color: #fcf8e3 !important;
    }

    a:hover {
        color: #ffbe00 !important;
    }

    .panel-heading {
        padding: 5px 5px !important;
    }

    .panel {
        border-radius: 0px !important;
        margin-bottom: -5px !important;
    }
}


#forgebox-modernize {
    width: 100%;
    margin: 0 auto !important;
    transform: scale(1.02,1);


    .width-100 {
        width: 100%;
    }

    .align-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    section {
        width: 100%;
        margin: 0 auto !important;
    }

    .main-content {
        margin-right: 10%;
        margin-left: 10%;
        padding-top: 3%;
        padding-bottom: 5%;
    }

    h2 {
        color: #757575;
        margin-bottom: 5%;
        font-size: 40px;
    }

    // header section 
    #header {
        background-color: #EEE;

        h1 {
            color: #075A77;
            margin-top: 5%;
            margin-bottom: 5%;
            font-size: 40px;
        }

        .text {
            color: #000;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 5%;
        }

        .buttons {
            display: flex;
            justify-content: space-evenly;
        }

        .btn {
            padding: 13px 35px;
            border-radius: 12px;
            font-size: 15px;
        }

        // *** media query ***
        @media only screen and (max-width: 600px) {
            .buttons {
                display: flow-root;
            }
        }    
    }


    // what is.. section
    #whatis {
        background-color: #349AC1;
        color: #fff;
        font-size: 23px;
        line-height: 1.52;

        span {
            font-weight: bold;
        }

        .img-responsive {
            border-radius: 8px;
        }

    }

    // empowering Coldfusion CFML Apps section
    #empowering {

        img {
            width: 100%;
        }

        .app-description {
            display: grid;
            align-content: center;
            text-align: left;
        }

        .d-flex {
            display: flex;
            padding: 5%;
        }

        h3 {
            font-size: 30px;
        }

        p {
            font-size: 23px;
        }
    }

    //matrix section
    #matrix {

        table {
            //border: 1px solid silver;
            text-align: center;

            //display: block;
            //overflow-x: auto;
            //white-space: pre-wrap;
        }

        .d-grid > p {
            text-align: center;
        }

        .fa-check-circle {
            color: #8fc73e;
            font-size: 20px;
            padding-top: 5%;
        }

        .fa-ban {
            color: #b4b4b4;
            font-size: 20px;
            padding-top: 5%;
        }        

        .btn {
            font-size: 12px;
            padding: 10px 15px;
        }

        th, td { 
            padding: 2%;
        } 

        th {
            color: #131313;
            text-align: center;
        }

        p {
            color: #003F53;
            font-weight: bold;
            text-align: left;
        }

        span {
            color: #003F53;
            font-size: 17px;
        }

    }

    //contact us section
    #contactus {
        h1 {
            font-size: 40px;
            color: #131313;
        }
    }

    // edition section
    #editions {
        width: 100%;
        height: auto;
        background-color: #EFFAFF;

        .see-features {
            display: none;
        }

        .btn-features {
            border: 0;
            background-color: #fff;
            text-decoration: underline;
            color: #009CC5;
        }

        .card-community {
            border: 2px solid #009CC5;
            border-radius: 8px;
            margin-bottom: 5%;
            background-color: #fff;
        }
    
        .community {
            background-color: #009CC5;
            color: #fff;
            font-weight: bold;
            padding: 3px;
        }

        .card-content {
            width: 100%;
            display: flex;
        }

        .card-description {
            width: 35%;
            text-align: center;
        }

        .card-detail {
            width: 65%;
            position: relative;
            padding-top: 2%;
            text-align: left;
        }

        .card-lista {
            column-count: 3;
        }
        
        .btn-join {
            position: absolute;
            bottom: 0;
            left: 37%;
        }

        .forever {
            font-size: 25px;
        }
        
        .description {
            font-size: 15px;
            height: 100px;
        }
    
        .fas {
            color: #8fc73e;
        }
    
        .card {
            border: 2px solid #009CC5;
            border-radius: 8px;
            background-color: #fff;
            height: 1080px;
            position: relative;
            margin-bottom: 5%;
        }
    
        .card-title {
            background-color: #009CC5;
            text-align: center;
            color: #fff;
            font-weight: bold;
            padding: 3px;
        }
    
        .card-body {
            padding: 6%;
            text-align: center;
            padding-bottom: 0px;
        }
    
        .btn-secondary {
            background-color: #838383;
            color: #fff;
            border: none;
        }

        .price {
            color: #003f53;
            font-weight: bold;
            font-size: 40px;
            margin-bottom: 0px;
        }

        .btn {
            font-size: 15px;
            padding: 8px 20px;
        }

        .btn-margin {
            margin-top: 2%;
            margin-bottom: 6%;
        }
    
        .card-footer {
            color: #009CC5;
            border-top: 1px solid silver;
            text-align: left;
            padding: 2%;
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        .features {
            text-align: left;
            padding: 6%;
            padding-top: 0px;
        }
    
        h3 {
            font-size: 45px;
            color: #003f53;
            margin-bottom: 0;
        }
    
        p {
            color: #757575;
            font-size: 14px;
        }
    
        span {
            color: #009CC5;
            font-weight: bold;
        }
        
        img {
            margin-top: 3%;
            margin-bottom: 3%;
            height: 130px;
        }
    
        label {
            color: #757575;
            margin-top: 0px;
            font-weight: 0;
            width: 100%;
        }
    }

}

@media only screen and (max-width: 600px) {

    #forgebox-modernize {
        transform: scale(1.09, 1);

        h2 {
            font-size: 30px;
        }

        .main-content {
            margin-right: 3%;
            margin-left: 3%;
        }

        .text-align {
            text-align: center;
        }

        #empowering {
            h3 {
                font-size: 25px !important;
            }

            p {
                font-size: 20px !important;
            }
        }

        #matrix {
            .btn {
                font-size: 10px !important;
                padding: 7px 1px !important;
            }
    
            th, td { 
                padding-top: 2% !important;
                padding-bottom: 2% !important;
            } 
    
            th {
                font-size: 14px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                padding: 2%;
            }

            p, span {
                font-size: 13px !important;
            }

            .fa-ban,  .fa-check-circle {
                font-size: 20px !important;
                padding-top: 5% !important;
            }

            .rotating {
                display: block !important;
            }

            .rotating-cell {
                width: 40px;
                height: 40px;
                -webkit-animation: rotation 3s infinite linear;
            }

            @-webkit-keyframes rotation {
                from {
                        -webkit-transform: rotate(0deg);
                }
                to {
                        -webkit-transform: rotate(140deg);
                }
            }
        }

        // editions seccion
        #editions {
            .card-description, .card-detail {
                width: 100%;
            }

            .btn-join {
                left: 32%;
            }

            .see-features {
                display: block;
            }
        
            .card-content {
                display: flow-root;
                padding: 6%;
            }
        
            .card-lista {
                column-count: 1;
                text-align: left;
            }
        
            .btn-join {
                position: inherit;
                left: 0;
            }

            .card {
                height: auto !important;
                margin-bottom: 5%;
            }

            .card-body {
                margin-bottom: 15%;
            }
        }
    }

}

@media only screen and (min-width: 600px) and (max-width: 768px) and (orientation: landscape) {
    
    #editions { 
        .btn-join {
            left: 35% !important;
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {


    #forgebox-modernize {
        transform: scale(1.044, 1);
    }

    #matrix {
        .btn {
            font-size: 9px !important;
            padding: 7px 1px !important;
        }

        th, td { 
            padding-top: 2% !important;
            padding-bottom: 2% !important;
        } 

        th {
            font-size: 12px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
            padding: 2%;
        }

        p, span {
            font-size: 12px !important;
        }

        .fa-ban,  .fa-check-circle {
            font-size: 20px !important;
            padding-top: 5% !important;
        }
    }

    #editions {

        .see-features {
            display: block !important;
        }

        .card {
            height: auto !important;
            margin-bottom: 5%  !important;
        }
        
        .card-content {
            display: grid !important;
        }
        .card-description {
            width: 100% !important;
        }
        .card-detail {
            width: 100% !important;
            margin-top: 9% !important;
            left: -3% !important; 
        }

        .card-body {
            padding: 3%  !important;
            padding-bottom: 0px  !important;
            margin-bottom: 18%  !important;
        }

        .description {
            height: 60px !important;
        }

        .btn-margin {
            margin-top: 5% !important;
            margin-bottom: 5% !important;
        }

        .btn-join {
            left: 38% !important; 
        }

    }
    
}


@media only screen and (min-width: 768px) and (max-width: 900px) and (orientation: landscape) {

    #forgebox-modernize {
        transform: scale(1.04, 1) !important;
    }

    #editions {
        .see-features {
            display: block;
        }

        .card-content{
            display: flow-root !important;
        }
        .card-content {
            padding: 0px !important;
        }
        .card-description {
            width: 100% !important;
        }
        .card-detail {
            position: unset;
            width: 100% !important;
            margin-top: 8% !important;
        }
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    #header {
        display: block ruby !important;
    }

    #matrix {
        .btn {
            font-size: 10px !important;
            padding: 7px 3px !important;
        }

        th, td { 
            padding-top: 2% !important;
            padding-bottom: 2% !important;
        } 

        th {
            font-size: 14px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
            padding: 2%;
        }

        p, span {
            font-size: 13px !important;
        }

        .fa-ban,  .fa-check-circle {
            font-size: 20px !important;
            padding-top: 5% !important;
        }
    }

    // editions seccion
    #editions {

        // modo horizontal
        @media (orientation: landscape) { 
            
        }

        //modo vertical
        @media (orientation: portrait) { 
            .card-content {
                padding: 0 !important;
            }

            .btn-join {
                left: 38% !important;
            }
        }

        .card-description, .card-detail {
            width: 100%;
        }

        .btn-join {
            left: 32%;
        }
    
        .card-content {
            display: flow-root;
            padding: 6%;
        }
    
        .card-lista {
            column-count: 1;
            text-align: left;
        }
    
        .btn-join {
            position: inherit;
            left: 0;
        }

        .card {
            height: auto !important;
        }

        .card-body {
            margin-bottom: 18%;
        }
    }
}


@media only screen and (min-width: 992px) and (max-width: 1024px) and  (orientation: landscape) {

    #forgebox-modernize {
        transform: scale(1.04, 1);
    }

    #editions {
        .card {
            height: 995px !important;
        }
    }

} 
