:root {
    --text-blue: #0B3188;
    --ortusgreen: #8FC73E;
}

@mixin transition {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, display 0.15s ease-in-out;
}

@mixin svgmask-bg($ico-url, $size, $width, $height, $display, $position, $bgcolor) {
    mask: url($ico-url) no-repeat $position;
    mask-size: $size;
    background-color: $bgcolor;
    display: $display;
    width: $width;
    height: $height;
}

// special classes 
.nodesktop {
    display: none;
}

.service-webdev-header {
    background: url(../../../images/services/webdev/bghead.svg) no-repeat top right;
    margin-bottom: 100px;
    .head-info-container {
        display: flex;
        justify-content: space-between;
        .webdev-ttl {
            display: flex;
            align-items: center;
            svg {
                margin-right: 30px;
            }
            .txt {
                color: var(--text-blue);
                h1 {
                    font-weight: 700;
                    margin: 0 0 5px;
                }
                p {
                    max-width: 414px;
                    margin: 0px;
                    font-size: 18px;
                }
            }
        }
        .webdev-img {
            img {
                width: 530px;
                height: auto;
            }
        }
    }
}

.webdev-options-container {
    .bgwavegreen-top {
        height: 42px;
        background: url(../../../images/services/webdev/wave-green-top.svg) left center no-repeat;
        background-size: cover;
    }
    .options-container {
        background: var(--text-blue);
        min-height: 280px;
        display: flex;
        .option {
            display: inline-flex;
            padding: 40px;
            color: #fff;
            gap: 20px;
            align-items: center;
            img {
                width: 95px;
                height: auto;
            }
            .option-txt {
                text-align: center;
                padding: 0 5px;
            }
            p.ttl {
                font-size: 24px;
                font-weight: bold;
            }
            a {
                border: 1px solid var(--ortusgreen);
                border-radius: 8px;
                display: flex;
                padding: 10px;
                text-align: center;
                color: var(--ortusgreen);
                justify-content: center;
                font-weight: bold;
                &:hover {
                    background: var(--ortusgreen);
                    color: var(--text-blue);
                    text-decoration: none;
                }
            }
        }
        .consulting {
            width: 33%;
            background: var(--text-blue);
        }
        .staffaug {
            width: 34%;
            background: #0E2F71;
        }
        .projects {
            width: 33%;
            background: #082766;
        }
    }
}

.webdev-skills-container {
    background: url(../../../images/services/webdev/wave-green-bottom.svg) top center no-repeat;
    background-size: contain;
    .skills {
        display: flex;
        padding: 200px 0;
        gap: 50px;
        .skills-list {
            .ttl {
                font-size: 3em;
                font-weight: bold;
                color: var(--text-blue);
                line-height: 1;
                margin-bottom: 30px;
                span {
                    display: block;
                    font-size: 1.8em;
                }
            }
            ul {
                list-style-image: url(../../../images/services/webdev/ico-bullet.svg);
                li {
                    padding: 8px 0;
                    font-size: 1.2em;
                }
            }
        }
    }
}

.webdev-tecnologies-container {
    background: #001745 url(../../../images/services/webdev/waves-stars-tecnologies.svg) top center no-repeat;
    padding: 230px 0;
    background-size: contain;
    h2 {
        font-size: 2.5rem;
        color: #fff;
        margin-bottom: 45px;
    }
    .tech-ico-container {
        display: flex;
        gap: 18px;
        flex-wrap: wrap;
        justify-content: center;
        .tech-ico {
            width: 174px;
            height: 174px;
            background: #fff;
            transition: background 0.30s ease-in-out;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            .txt-hover {
                // display: none;
                opacity: 0;
                position: absolute;
            }
            img {
                width: 50%;
                height: auto;
            }
            &:hover {
                background: rgba($color: #00418D, $alpha: 0.75);
                justify-content: center;
                align-items: center;
                text-decoration: none;
                img {
                    opacity: 0.2;
                }
                .txt-hover {
                    position: absolute;
                    opacity: 1;
                    color: #fff;
                    font-size: 18px;
                    font-weight: bold;
                    transition: opacity 0.45s ease-in-out;
                }
            }
        }
    }
}

@media(max-width:1200px) {
    .service-webdev-header {
        background-size: 60%;
    }
    .webdev-options-container {
        .options-container {
            .option {
                flex-direction: column;
                justify-content: space-between;
                padding: 20px;
                .txt {
                    height: 90px;
                }
            }
        }
    }
}

@media(max-width:1080px) {
    .webdev-options-container {
        .options-container {
            flex-direction: column;
            .option {
                width: 100%;
                padding: 40px;
                .option-txt {
                    max-width: 360px;
                }
                .txt {
                    height: auto;
                }
            }
        }
    }
    .webdev-tecnologies-container {
        .tech-ico-container {
            gap: 15px;
        }
    }
}

@media(max-width:992px) {
    .service-webdev-header {
        background-size: contain;
        .head-info-container {
            flex-direction: column-reverse;
            align-items: center;
            padding-top: 60px;
            .webdev-ttl {
                margin: 50px auto;
            }
            .webdev-img {
                img {
                    width: 530px !important;
                    height: auto;
                }
            }
        }
    }
    .webdev-options-container {
        .bgwavegreen-top {
            background-position-x: right;
        }
        // .greenwave {
        //     width: 100%;
        //     height: auto;
        // }
        .options-container {
            .option {
                padding: 60px;
                gap: 15px;
                .option-txt {
                    max-width: 600px;
                    .ttl {
                        font-size: 2rem;
                    }
                    .txt {
                        font-size: 1.3em;
                        margin: 10px auto 30px;
                    }
                    a {
                        font-size: 1.3em;
                    }
                }
                .txt {
                    height: auto;
                }
            }
        }
    }
    .webdev-skills-container {
        .container {
            width: 100% !important;
        }
    }
    .webdev-tecnologies-container {
        .container {
            width: 100% !important;
        }
    }
}

@media(max-width:880px) {
    .webdev-skills-container {
        .skills {
            flex-direction: column;
            img {
                max-width: 350px;
                margin: 0 auto;
            }
            .skills-list {
                max-width: 360 px;
                margin: 0 auto;
            }
        }
    }
    .webdev-tecnologies-container {
        h2 {
            text-align: center;
        }
        .tech-ico-container {
            .tech-ico {
                width: 250px;
                height: 250px;
                .txt-hover {
                    font-size: 2rem;
                }
                &:hover {
                    .txt-hover {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

@media(max-width:768px) {}

@media(max-width:600px) {
    .service-webdev-header {
        .head-info-container {
            .webdev-ttl {
                svg {
                    display: none;
                }
                .txt {
                    text-align: center;
                }
            }
        }
    }
    .webdev-options-container {
        .bgwavegreen-top {
            background-position-x: center;
        }
    }
    .webdev-tecnologies-container {
        .tech-ico-container {
            .tech-ico {
                width: calc(50% - 8px);
                height: auto;
                padding: 20px 0;
            }
        }
    }
}