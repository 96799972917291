#sponsors-page {
    .header {
        background-image: url("../../images/sponsors/bg-top-desk.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 860px;
    }

    .bg-black-opacity {
        background-color:rgba(0,0,0,0.5);
    }

    .bg-green-opacity {
        background-color:rgba(143,199,62,0.9);
    }

    .card-sponsor {
        &:hover .card-hover {
            @apply tw-opacity-100;
        }
    }

    .carousel-control {
        color: #959292;
    }

    .carousel-indicators li {
        border: 1px solid #686565;
    }

    .carousel {
        padding: 15px;
    }

    .carousel-indicators {
        bottom: 0px;
    }

    .carousel-indicators .active {
        background-color: #cac5c5;
    }

    .carousel-control.right {
        background-image: none !important;
    }

    .carousel-control.left {
        background-image: none !important;
    }

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        .header {
            height: auto;
        }
    }
}