#ortus-university .jumbotron {
    color: #00577A;
}

#ortus-university .btn-primary {
    background-color: #00577A;
    background-image: none;
    border: none;
}

#ortus-university h2 {
    color: #00577A;
    text-align: center;
}

#ortus-university #courses {
    background-color: #F6F8F8;
    padding-top: 25px;
    padding-bottom: 40px;
}

#ortus-university .course-item {
    background-color: #fff;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, .2);
    margin: 8px 15px;
    min-height: 222px;
    padding: 15px 15px;
    position: relative;
}

#ortus-university .course-item .btn {
    position: absolute;
    left: 30px; /*38px;*/
    top: 144px; /*160px;*/
    width: 100px;
	white-space: normal;
	font-size: 15px;
	padding: 5px;
}

#ortus-university .course-title {
    font-size: 1.3em;
    font-weight: bold;
    margin-top: 35px;
}

#ortus-university .course-image-box {
    float: left;
}

#ortus-university .course-info {
    margin-left: 165px;
}

#ortus-university .course-product {
    font-weight: bold;
    left: 180px;
    position: absolute;
    top: 15px;
}

#ortus-university .course-time {
    right: 15px;
    position: absolute;
    top: 15px;
}

#ortus-university .course-desc {
    font-size: 15px
}

#ortus-university .restrict-height {
    height: 165px;
    overflow: hidden;
}

#ortus-university .show-more {
    background: none;
    border: none;
    margin-left: 165px;
    color: #02749c;
    padding: 0px;
}

#ortus-university #whatis {
    background-color: #fff;
    padding-top: 25px;
    padding-bottom: 40px;
}

.popped-title-section {
    margin: 25px 20%;
    background-color: #FFF;
    border: 1px solid #A6A6A6;
    border-radius: 20px;
    padding: 20px 30px;
    position: relative;
}

.popped-title {
    border-radius: 10px;
    margin: 0;
    position: absolute;
    padding: 10px;
    text-transform: uppercase;
    top: -25px;
}

.popped-title-left {
    left: -20px;
}

.popped-title-right {
    right: -20px;
}

.illustration-row {
    background-repeat: no-repeat;
    min-height: 270px;
}

.illustration-left {
    background-position: left bottom;
}

.illustration-right {
    background-position: right bottom;
}

.primary-bg {
    background-color: #00577A;
    color: #FFF;
}

.secondary-bg {
    background-color: #81C536;
    color: #FFF;
}

.titretary-bg {
    background-color: #000;
    color: #FFF;
}

.trainingQuote-slide{
    background-color: white;
    width: 100%;
    height: 350px;
}