.searchResults .searchResultsCount {
    margin-bottom: 20px;
}

.searchResults ul {
    list-style: none;
    margin-left: -20px;
}

.searchResults li {
    margin: 5px 0px;
    color: #565656;
    padding: 5px 0px;
    line-height: 1.8em;
}

.searchResults li a {
    font-weight: bold;
}

.searchResults .highlight {
    background-color: yellow;
    font-weight: bold
}

.searchResults cite {
    color: green;
    background-color: #f5f5f5;
    padding: 5px;
}

#search-header {
    background-color: #13213C;
    color: white;
    text-align: center;
    display: block;
    max-height: 300px;
    height: 210px;
    padding: 10px;
    letter-spacing: 1.25px;

    img {
        width: 100%;
        max-width: 225px;
        position: absolute;
        right: 100px;
        top: 125px;
        @media screen and (max-width: 1025px){
            max-width: 197px;
            right: 20px;
            top: 98px;
        }
    }
}

.standard-curve {
    height: 180px;
    background: url("../../images/standard-top-foo.svg") no-repeat;
    background-position: bottom center;

    @media screen and (max-width: 1500px) {
        height: 80px;
    }
}