$success: #4fa425;
$danger: #dc350f;
$warning: #c4720d;
$neutral: #ddd;

/* color pallete */
$bright-green: #8fc73e;
$medium-green: #689329;
$dark-green: #4c7411;
$bright-blue: #3B9CDA;
$dark-blue: #163e51;
$grey-blue: #3E6167;
$grey: #757575;
$plain-black: #000000;

/* primary brand colors */
$primary: $bright-green;
$secondary: $dark-blue;
$tertiary: $medium-green;
$neutral-light: $grey;
$dark-grey: darken( $grey, 10% );

:root {
    --color-primary:#{ $primary };
}