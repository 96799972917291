.head-security-section {
    position: relative;
    background: #0F347E url(../../../images/services/security/stars.svg) center -50px no-repeat;
    background-size: cover;
    padding-top: 60px;
    
    &:after {
        content: '';
        position: absolute;
        bottom: -116px;
        width: 100%;
        min-height: 150px;
        left: 0;
        background: url("../../../images/services/mobileapp/wave-stats-bottom.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: auto;
    }
}

.security-stats-wavedown {
    background: url(../../../images/services/security/upperwave.svg) center bottom no-repeat;
    height: 118px;
}

.bg-performance {
    background: linear-gradient(#2889AD, #0F497E);
    background-image: url(../../../images/services/security/foo-section-2.svg) center bottom no-repeat;
}

.performance-wavedown {
    background: url(../../../images/services/security/foo-section-2.svg) center bottom no-repeat;
    background-size: 100%;
    min-height: 350px
}

.wavy-bg {
    position: relative;
    background: rgb(40,137,173);
    background: linear-gradient(0deg, rgba(40,137,173,1) 20%, rgba(15,73,126,1) 100%);
    &:before {
        content: '';
        position: absolute;
        top: -56px;
        width: 100%;
        min-height: 60px;
        left: 0;
        background: url("../../../images/services/security/upperwave.svg");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }
    &:after {
        content: '';
        position: absolute;
        bottom: -55px;
        width: 100%;
        min-height: 150px;
        left: 0;
        background: url("../../../images/services/security/foo-section-2.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
}

@media (min-width: 769px){
    .wavy-bg {
        &:before {
            top: -65px;
            min-height: 70px;
        }
        &:after {
            bottom: -60px;
            min-height: 170px;
        }
    }
}
@media (min-width: 1081px){
    .wavy-bg {
        &:before {
            top: -80px;
            min-height: 80px;
        }
        &:after {
            bottom: -69px;
            min-height: 227px;
        }
    }
}
@media (min-width: 1476px){
    .wavy-bg {
        &:before {
            top: -99px;
            min-height: 100px;
        }
        &:after {
            bottom: -84px;
            min-height: 282px;
        }
    }
}
@media (min-width: 1820px){
    .wavy-bg {
        &:before {
            top: -135px;
            min-height: 150px;
        }
        &:after {
            bottom: -100px;
            min-height: 408px;
        }
    }
}