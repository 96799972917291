#cfcasts {
    margin-top: 0;
    width: 100%;

    .flex {
        display: flex;
    }

    .justify-between {
        justify-content: space-between;
    }

    .w-50 {
        width: 50%;
    }

    .w-80 {
        width: 80%;
    }

    .w-full {
        margin-left: -15px;
        margin-right: -15px;
    }

    .mb-2 {
        margin-bottom: 2%;
    }

    .mb-5 {
        margin-bottom: 5%;
    }

    .mr-3 {
        margin-right: 3%;
    }

    .my-6 {
        margin-top: 6%;
        margin-bottom: 6%;
    }

    .font-bold {
        font-weight: bold;
    }

    .text-xl {
        font-size: 5rem;
    }

    .text-md {
        font-size: 2rem;
    }

    .text-blue-600 {
        --text-opacity: 1;
        color: #369bc2;
        color: rgba(54,155,194,var(--text-opacity));
    }

    .text-green-ortus {
        color: #8FC73E;
    }

    .text-blue-700 {
        --text-opacity: 1 !important;
        color: #2b6cb0 !important;
        color: rgba(43,108,176,var(--text-opacity)) !important;
    }

    .text-blue-700:hover {
        --text-opacity: 1 !important;
        color: #fff !important;
        color: rgba(255,255,255,var(--text-opacity)) !important;

        --bg-opacity: 1 !important;
        background-color: #4299e1 !important;
        background-color: rgba(66,153,225,var(--bg-opacity)) !important;
    }

    .border-blue-500 {
        --border-opacity: 1 !important;
        border-color: #4299e1 !important;
        border-color: rgba(66,153,225,var(--border-opacity)) !important;
    }

    .bg-blue-700 {
        --text-opacity: 1 !important;
        color: #fff !important;
        color: rgba(255,255,255,var(--text-opacity)) !important;

        --bg-opacity: 1 !important;
        background-color: #4299e1 !important;
        background-color: rgba(66,153,225,var(--bg-opacity)) !important;
    }

    .bg-blue-700:hover {
        color: white;
        background-color:#2b6cb0 !important;
    }

    .sm-hidden {
        display: block;
    }

    /* SECTIONS */
    #cfcasts-header {
        text-align: center;
        display: block;
        max-height: 626px;
        margin-bottom: 10%;
        padding: 5%;
        letter-spacing: 1.25px;
        background: linear-gradient(#0E2148, #160A1D);

        .cfcasts_log {
            width: 30%;
        }

        .laptop {
            width: 75%;
        }
    }

    #cards {
        .card {
            border: 1px solid silver;
            border-radius: 10px;
            height: 500px;
            padding: 5%;
        }

        ul {
            list-style: none;
            text-align: left;
        }

        ul > li {
            margin-top: 8%;
            margin-bottom: 8%;
            color: #000;
        }

        .card-title {
            font-size: 2.1rem;
            color: #000;
        }

        .card-price {
            color: black;
            font-weight: bold;
            font-size: 5rem;
        }

        .interval {
            font-size: 3rem;
            font-weight: normal;
        }
    }

    #whats {
        background-color: #eee;
        color: #6f6e6e;
        text-align: left;
        display: block;
        max-height: 500px;
        padding: 4%;
        letter-spacing: 1.25px;

        .circle-section {
            text-align: right;
        }
    }

    #training {
        width: 100%;

        .card-training {
            margin-top: 5%;
            border: 1px solid silver;
            border-radius: 5px 5px 5px 5px;
            box-shadow: 0px 0px 8px #888888;
        }

        .card-img-top {
            height: 180px;
            width: 100%;
            display: block;
            border-radius: 5px 5px 0px 0px;
        }

        .card-body {
            text-align: left;
            padding: 5%;
            height: 20rem;
        }

        .card-link {
            color: #4299e1;
        }

        .card-text {
            margin-top: 3%;
        }

        .bottom {
            position: absolute;
            bottom: 3%;
        }

        .explore {
            padding-left: 6% !important;
            padding-right: 6% !important;
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {

        h1{
            font-size: 3rem;
        }

        .text-xl {
            font-size: 2rem;
        }
    
        .text-md {
            font-size: 1.5rem;
        }

        #cfcasts-header {
            max-height: 270px;
            margin-bottom: 15%;

            .cfcasts_log {
                width: 60%;
            }
    
            .laptop {
                width: 100%;
            }
        }

        #cards {
            .card {
                margin-top: 5%;
            }

            .card-list {
                padding-left: 5%;
            }
        }

        #whats {
            .circle {
                display: none;
            }
        }

    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) {
        .w-80 {
            width: 85%;
        }

        #cfcasts-header {
            max-height: 492px;
            margin-bottom: 15%;

            .cfcasts_log {
                width: 60%;
            }
    
            .laptop {
                width: 100%;
            }
        }

        #whats {
            text-align: justify;

            .circle-section {
                text-align: center;
            }

            .circle {
                display: none;
            }
        }

        #cards {
            .card {
                margin-top: 5%;
            }

            .card-list {
                padding-left: 25%;
            }

            ul > li {
                margin-top: 4%;
                margin-bottom: 4%;
            }
        }

    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-width: 991.98px) {
        .w-80 {
            width: 86%;
        }

        .sm-hidden {
            display: none;
        }

        #cfcasts-header {
            max-height: 505px;
            margin-bottom: 15%;

            .cfcasts_log {
                width: 60%;
            }
    
            .laptop {
                width: 90%;
            }
        }

        #cards {
            .card-price {
                font-size: 4rem;
            }
            .card-list {
                padding-left: 3%;
            }
        }

        #whats {

            .circle-section {
                text-align: center;
            }

            .circle {
                width: 30%;
            }
        }

    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) and (max-width: 1199.98px) {
        #cfcasts-header {
            max-height: 535px;
        }
    
        #whats { 
            .circle-section {
                text-align: center;
                height: 368px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .circle {
                width: 70%;
            }
        }
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        
    }
}
