:root {
    --text-blue: #0B3188;
    --ortusgreen: #8FC73E;
}

@mixin transition {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, display 0.15s ease-in-out;
}

@mixin svgmask-bg($ico-url, $size, $width, $height, $display, $position, $bgcolor) {
    mask: url($ico-url) no-repeat $position;
    mask-size: $size;
    background-color: $bgcolor;
    display: $display;
    width: $width;
    height: $height;
}

// special classes
.nodesktop {
    display: none;
}

.btn-primary {
    padding: 10px 20px;
}

.ttlcat {
    font-size: 32px;
    line-height: 1;
    font-weight: bold;
}

.txtcat {
    font-size: 18px;
}

.images-inline {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px auto;
}

.devops-head {
    background: url(../../../images/services/bg-services-head.svg) top center no-repeat;
    .devops-head-detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 30px;
        .devops-head-txt {
            display: flex;
            .devops-headico-detail {
                margin-right: 20px;
                svg {
                    fill: #fff;
                }
            }
        }
    }
    .breadcrumbs {
        margin: 10px 0;
    }
}

.devops-streamline {
    display: flex;
    padding: 50px 0;
    align-items: center;
    .streamline-txt {
        .p {
            width: 325px;
        }
    }
}

.containarized {
    background: var(--text-blue) url(../../../images/services/devops/bg-containerblocks.svg) top center no-repeat;
    padding: 250px 20px 150px;
    text-align: center;
    p {
        text-align: center;
    }
    .images-inline {
        max-width: 800px;
        margin: 50px auto;
    }
}

.power-dev {
    //background: url(../../../images/services/devops/bg-powerdev.svg) top center no-repeat;
    padding: 50px 20px 100px;
    display: flex;
    justify-content: space-between;
    .left {
        width: calc(50% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .images-inline {
            img {
                width: 30%;
                max-width: 125px;
            }
        }
    }
    .right {
        width: calc(50% - 20px);
        img {
            width: 100%;
            height: auto;
        }
    }
}

.got-question {
    background: #082766 url(../../../images/services/devops/wave-gotquestion.svg) top center no-repeat;
    padding-bottom: 200px;
    margin-top: 150px;
    background-size: contain;
    .pj-txt {
        max-width: 600px;
        a {
            color: var(--ortusgreen);
            &:hover {
                color: #00dee6;
                text-decoration: none;
            }
        }
    }
}

@media(max-width:1200px) {
    .devops-head-img-detail {
        margin-top: 135px;
    }
    .devops-streamline {
        flex-direction: column-reverse;
        padding-top: 100px;
    }
}

@media(max-width:1080px) {}

@media(max-width:1024px) {
    .devops-head {
        //background-size: contain;
        align-items: center;
        .headcontainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        .devops-head-detail {
            justify-content: center;
            padding: 0px;
            .devops-head-txt {
                margin-bottom: 50px;
                flex-direction: column;
                align-items: center;
                h1 {
                    text-align: center;
                    font-size: 2rem;
                }
            }
        }
    }
    .devops-head-img-detail {
        max-width: 400px;
        margin: 0 auto;
    }
}

@media(max-width:992px) {
    .devops-streamline {
        width: 80%;
        margin: 0 auto;
    }
    .power-dev {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        .left {
            width: 100%;
            margin-bottom: 60px;
        }
        .right {
            display: flex;
            justify-content: center;
            width: 100%;
            img {
                max-width: 350px;
            }
        }
    }
}

@media(max-width:880px) {}

@media(max-width:768px) {}

@media(max-width:600px) {
    .devops-streamline {
        width: 100%;
        .ttlcat {
            text-align: center;
        }
        .txtcat {
            text-align: center;
        }
    }
    .power-dev {
        width: 100%;
        .powerdev {
            margin-bottom: 50px;
        }
        .migration {
            margin-bottom: 50px;
        }
    }
    .containarized {
        .images-inline {
            flex-direction: column;
            gap: 60px;
        }
    }
}