.ortus-basic-carousel{
	margin-bottom: 40px;

	.pic {
		background: rgba( 255,255,255,.7 );
        margin: auto;
        padding: 15px;
        max-width: 150px;
    }
    .owl-dots{
        text-align: center;
    }
    button.owl-dot{
        background-color: $neutral;
        border-radius: 4px;
        height: 8px;
        margin: 5px;
        width: 8px;
    }
    button.owl-dot.active{
        background-color: $grey;
    }
}
.ortus-content-carousel{
    .item{
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        min-height: 500px;
    }
    .owl-item img.img-responsive{
        width: auto;
        margin: 0 auto;
    }
    .owl-nav button.owl-prev, .owl-nav button.owl-next{
        color: $neutral;
        font-size: 4em;
        position: absolute;
        top:35%;
    }
    .owl-nav button.owl-prev {
        left:30px;
    }
    .owl-nav button.owl-next{
        right:30px;
    }
    .owl-dots{
        text-align: center;
        position: absolute;
        bottom: 0;
        width:100%;
    }
    button.owl-dot{
        background-color: $neutral;
        border-radius: 4px;
        height: 8px;
        margin: 5px;
        width: 8px;
    }
    button.owl-dot.active{
        background-color: $grey;
    }
    h3.slide-title {
        font-size: 2.5em;
        font-weight: bold;
    }
    .slide-pic, .slide-content{
        padding: 25px;
    }
    .slide-description{
        font-size: 1.8em;
    }
    .slide-buttons{
        margin: 15px 0px;
    }
}


#slider {
    width: 100%;
    height: 419px;
    margin: 0;
}

#slider h3 {
    font-weight: normal;
    color: #fff;
    font-size: 24px;
    width: 215px;
    float: left;
}

#slider span.author {
    float: right;
    text-align: right;
    width: 120px;
    font-size: 12px;
    color: #000;
    margin-top: 8px;
}

div#slideshow {
    float: left;
    width: 100%;
    height: 419px;
}

.slider-item {
    width: 100% !important;
    height: 419px;
}

.slider_center {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
}

.slider_content {
    float: left;
    margin-top: 100px;
    width: 629px;
    height: 195px;
    position: relative;
    left: 0;
}

.slider_content_inner {
    float: left;
    width: 530px;
    padding: 15px;
}

.slider_content .small {
    float: left;
    width: 400px;
    padding: 15px;
}

.slider_content_inner h2 {
    font: normal 26px/1.4em Arial, Helvetica, sans-serif;
}

.slider_content_inner p {
    margin: 10px 0px 8px 0px;
    float: left;
    clear: both;
    width: 100%;
    display: block;
    font: normal 13px/1.6em Arial, Helvetica, sans-serif;
}

.slider_content_inner h2.simple_1 {
    color: #FFF;
}

.slider_content_inner p.simple_1 {
    color: #de9062;
}

.slider_content_inner h2.simple_2 {
    color: #FFF;
}

.slider_content_inner p.simple_2 {
    color: #caeaff;
}

.slider_content_inner h2.simple_3 {
    color: #FFF;
}

.slider_content_inner p.simple_3 {
    color: #cecece;
}

.slider_content_inner h2.simple_4 {
    color: #000000;
}

.slider_content_inner p.simple_4 {
    color: #6c6c6c;
}

.slider_content_inner img {
    border: none;
}

.controls-center {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
}

#slider_controls {
    float: right;
    position: relative;
    top: -15px;
    margin: 0;
    z-index: 1000;
    width: 960px;
}

#slider_controls ul {
    margin: 0 auto;
    padding: 0px 0px 0px 35px;
    width: 150px;
}

#slider_controls ul li {
    margin: 0;
    padding: 0;
    list-style: none;
}

#slider_controls ul li {
    float: left;
    display: block;
}

#slider_controls ul li {
    width: 11px;
    height: 11px;
    background: url('../../images/slider_nav_normal.gif') no-repeat center center;
    display: block;
    float: left;
    padding: 2px;
    margin: 2px;
    outline: none;
}

#slider_controls ul li a {
    width: 11px;
    height: 11px;
    display: block;
}

#slider_controls ul li a:focus {
    outline: none;
}

#slider_controls ul li a:hover,
#slider_controls ul li.activeSlide {
    background: url('../../images/slider_nav_active.gif') no-repeat center center;
}


/* Home Carousel */

.home-car>.item>img,
.home-car>.item>a>img {
    width: 100%;
    margin: auto;
}

#home-car-more-btn {
    margin-top: 10px;
}

.home-car>.item>.row>.col-sm-6>p {
    margin-top: 70px;
    margin-right: 25px;
    margin-left: 25px;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 200px;
    font-size: 46px;
}

.home-car>.item>.row>.col-sm-12>p {
    margin-top: 70px;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 200px;
    font-size: 56px;
}

.home-car>.item {
    max-height: 800px;
}

#home-car {
    margin: 0px;
    padding: 0px;
}

a.carousel-control.home:hover {
    background: none;
}

#home-car-ctrl {
    background: none;
}

.carousel-control.home {
    text-shadow: none;
    color: gray;
    margin-top: 200px;
}

.carousel-control.home:hover {
    text-shadow: none;
    color: gray;
}

.carousel-control.home:focus {
    text-shadow: none;
    color: gray;
}

#home-cart-ind>li {
    border-color: gray;
    height: 10px;
}

#home-cart-ind>li.active {
    background-color: gray;
}


/* bootstrap slider */

#carousel-ortus {
    background-color: #A8DAE5;
    position: relative;
}

#carousel-ortus img {
    height: 350px;
}

.textBox {
    padding: 18px;
}

.carousel-inner h1 {
    font-size: 65px;
    font-weight: 700;
    color: #fff;
}

.carousel-inner h2 {
    font-size: 40px;
    color: #4C6166;
}