.form-control{
	font-size: 16px;
	height: 43px;
	padding: 20px 20px;
	background-color: #fff;
	border: 0px none;
}
.form-control:focus{
	background-color: rgb(226, 236, 250);
	font-weight: 500;
}
select.form-control{
	padding: 10px 20px;
}
.form-group{
	margin-top: 18px;
	margin-bottom: 18px;
}
.label{
	margin-bottom: 10px;
	font-weight: 600px;
}
.textarea{
	padding: 10px 20px;
}
.form-check-input{

}