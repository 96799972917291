.recorded-events-height {
    height: 650px;
}

.events-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.event-bg-card {
    background: url("../../../images/events/overview/bg-events.png");
    background-size: cover;
    background-position: top center;
}

.event-card {
    width: 340px;
    margin: 10px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
    border-radius: 18px;
    position: relative;
    margin-top: 40px;
}

@media (max-width: 915px) {
    .recorded-events-height {
        height: 700px;
    }
}

@media (max-width: 768px) {
    .recorded-events-height {
        height: auto;
    }
}