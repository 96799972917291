.head-product {
    background-image: url("../../images/products/bg_header_products.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 735px;
}

.learning {
    background-image: url("../../images/products/bg_learn.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 675px;
}

#panel-products {
    .panel-group {
        margin-bottom: 10px !important;
    }

    .panel {
        border: 0px solid transparent !important;
    }

    .panel-group .panel {
        border-radius: 0px !important;
    }

    .panel-heading {
        color: #fff !important;
        background-color: #19305C !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .list-group-item {
        background-color: #19305C !important;
        border: 0 !important;
    }

    .list-group-item:focus {
        color: #fff !important;
    }

    .panel-warning {
        border-color: #19305C !important;
    }

    a:focus {
        outline: 0px !important;
        outline-offset: 0px !important;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .head-product {
        height: 745px;
    }

    .learning {
        height: 1370px;
    }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .head-product {
        height: 870px;
    }

    .learning {
        height: 870px;
    }
}
