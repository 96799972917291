blockquote {
    quotes: "“" "”" "‘" "’";
    line-height: 1.1;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    margin: 5%;
    &::before{
        content: open-quote;
        text-indent: -0.45em;
        font-size: 2.5rem;
    }
    &::after {
        content: close-quote;
    }
   
}
@supports ( hanging-punctuation: first) {
    blockquote {
      text-indent: 0;
      hanging-punctuation: first;
    }
  }

#sidebar-recententries {
    font-size: 13px;
}

#sidebar-recententries ul {
    margin-left: 10px;
}

.post {
    margin-top: 60px;
    margin-bottom: 60px;
}

.post h2 a {
    color: #525252;
    text-decoration: none;
}

.post #post-info-bar {
    margin-top: 50px;
}

.categoryList {
    margin: 5px;
    font-size: 13px;
}

.gravatar {
    float: none !important;
    vertical-align: middle !important;
}

/** ============================== BLOG COMMENTS =============================== **/

.comment {
    border-bottom: 1px solid #f5f5f5;
    color: #222222;
    margin-bottom: 18px;
    margin-left: 5px;
}

.commentContent {
    clear: both;
    margin: 15px 0px 15px 10px;
}

.commentPicture {
    height: 70px;
    margin: 0px 0px 10px 0px;
    color: #565656;
    padding: 5px;
    background-color: #f5f5f5;
    -moz-box-shadow: 0 0 5px #ccc;
    -webkit-box-shadow: 0 0 5px #ccc;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
}

.commentPicture img {
    vertical-align: middle;
    margin: 5px;
    float: left !important;
}

.commentPicture p {
    float: left;
    padding: 8px 0px 0px 5px;
}

.btn-outline-ortus {
    color: #8FC73E;
    background-color: transparent;
    background-image: none;
    border-color: #8FC73E;
}

.btn-outline-ortus:hover {
    color: #fff;
    background-color: #8FC73E;
    background-image: none;
    border-color: #fff;
}

.nothing-found {
    width: 80px;
    margin: 0 auto;
}
/** ============================== NEW BLOG DESIGN =============================== **/

// global vars
$blue: #162A5A;

.text-blue-base {
    color: $blue;
}

.bg-blue-base {
    background-color: $blue;
}

/** HEADER **/

.featured-post-header {
    position: relative;
    background-color: $blue;
    background-image:url(../../images/stars-bg.svg);
    background-repeat: repeat;
    background-position: left bottom;
}

.featured-post-header::before {
    content: "";
    position: absolute;
    bottom: -7px;
    width: 100%;
    height: 36%;
    left: -90px;
    background: url('../../images/blog-top-foo.svg') no-repeat;
    background-position: bottom left;
    @media (min-width: 1535px) {
        width: 80%;
        height: 40%;
        left: 100px;
    }
    @media (max-width: 1023px) {
        height: 12%;
    }
    @media (max-width: 640px) {
        bottom: -3px;
        left: 0px;
        background: url('../../images/blog-top-foo-xs.svg') no-repeat;
        background-position: bottom center;
        width: 100%;
        height: 15%;
    }
}
.detail-post-header {
    position: relative;
}
.detail-post-header::after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 18%;
    height: 36%;
    left: 0px;
    background: url('../../images/waves-internal.svg') no-repeat;
    background-position: bottom left;
    @media (min-width: 1535px) {
        width: 18%;
        height: 30%;
    }
    @media (max-width: 1023px) {
        height: 30%;
        width: 38%;
    }
    @media (max-width: 640px) {
        bottom: -2px;
        background-position: bottom center;
        width: 100%;
        height: 15%;
    }
}
// Pagination
.pagination > li > a, .pagination > li > span {
    padding: 6px 15px;
    color: #8FC73E;
    border: 0px;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    color: #fff;
    background-color: #8FC73E;
    border-color: #8FC73E;
}

.tag-color > a {
    color: #8FC73E !important;
    border: transparent !important;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0;
}

.post-tags > a {
    color: #007faa !important;
}

.coverImage:hover{
    opacity: 0.7;
}


.excerpt {
    blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr{
        font-size: 17px !important;
        font-weight: normal !important;
        border: none;
        color: inherit;
        font-family: inherit;
        padding: inherit;
        margin: inherit;
    }
    blockquote{
        &::after, &::before {
            font-size: inherit!important;
        }
    }
    img {
        display: none;
    }
}

#body-post {
    h1, h2 {
        margin-top: 1.2em;
        margin-bottom: 0.5em;
    }
    h3, h4, h5, h6 {
        margin-top: 0.7em;
        margin-bottom: 0.5em;
    }
    .prettyprint, .syntaxhighlighter {
        margin: 1em 0 1em 0 !important;
    }
    @media (max-width: 640px) {
        p {
            text-align: left;
        }
    }
}

/** ============================== BLOG Search =============================== **/

.blog {
    #searchForm {
        display: block;
    }
    .input-group {
        width: 100%;
        &:active, :focus, :focus-visible, :focus-within{
            background-color: #cfd4db;
        }
    }

    .form-control {
        background-color: transparent;
        border: none;
        box-shadow: none !important;
        border-bottom: 1px solid rgb(173, 173, 173) !important;
        border-radius: 0px;
    }

    .btn-primary {
        opacity: 1;
        margin-left: 10px;
        margin: 0px auto;
        border-color: transparent;
        background-color: transparent !important;
        color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
        padding: 6px 12px;
    }

    .btn-primary:hover {
        background-color: #c6cad0 !important;
        border-radius: 5px !important;
        color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
    }

    .btn-primary:focus {
        margin-left: 1px;
        border-radius: 5px !important;
        border: 1px solid silver;
    }

    .pagingrow {
        margin-bottom: 180px;
    }
}