.bg-courses-head {
    background-image: url("../../../images/learn/courses/bg-courses-head.svg");
    background-size: cover;
    height: 360px;
}

#our-course-detail {
    h2 {
        @apply tw-text-gray-800 tw-text-3xl tw-my-5
    }
    h3 {
        @apply tw-text-gray-800 tw-text-xl tw-font-bold tw-my-3
    }

    ul{
        list-style: none;
        margin-left: 5px;
        margin-right: 0px;
    }
      
    ul li::before {
        content: "\2022";  
        color: var(--ortusgreen); 
        display: inline-block; 
        width: 1em;
        margin-left: -0.9em;
        font-weight: bold;
        font-size:1.5rem;
    }
}

.bg-instructor {
    background-color: var(--ortusgreen);
}

.course-card {
    &:hover {
        text-decoration: none;
    }
    &:hover .card-logo {
        @apply tw-bg-white;
    }
    &:hover .card-body p {
        @apply tw-text-white;
    }
    &:hover .card-body p span {
        @apply textOrtus;
    }
}