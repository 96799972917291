$blue-ortus: #00C1FF;
$green-ortus: #8FC73E;

.text-blue-ortus {
    color: $blue-ortus;
}

#books {

    a:focus{
        color: #4a5568 !important;
    }

    .other-books {
        background-image: url("../../images/books/bg_otherbooks.jpg");
        background-color: #181F32;
    }

    .img-book {
        background-color: #242D4A;
    }
    .img-book:hover {
        background-image: url("../../images/books/bg_hover_books.png");
        background-repeat: no-repeat;
        background-size: cover;
    }
}


/*
    * 102 Tips & Tricks Book Styles
*/

.header-102 {
    background-image: url("../../images/books/bg_header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 860px;

    a:focus{
        color: #4a5568 !important;
    }
}

.tips-section {
    background-image: url("../../images/books/bg_thebook.png");
    background-repeat: no-repeat;
    background-color: #223A8B;
}

.author-section {
    background-image: url("../../images/books/bg_author.png");
    background-repeat: no-repeat;
    background-color: #181E32;
}

.order-now {
    a:focus{
        color: #4a5568 !important;
    }
}


/*
    * ColdFusion in 100 minutes Book Styles
*/

.header-100-minutes {
    background-image: url("../../images/books/bg_header_cfml100minutes.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 860px;

    a:focus{
        color: #4a5568 !important;
    }
}

.author-100 {
    background-image: url("../../images/books/bg_autor_100minutes.jpg");
    background-repeat: no-repeat;
    background-color: #004F61;
}

.text-green-950 {
    color: #004F61;
}