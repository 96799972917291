.bi-report-container {
    background: #0F347E;
    padding: 0px 20px;
    height: 650px;
}

.bi-stats-waveup {
    background: url(../../../images/services/bIntelligence/bg-wave-top.svg) center no-repeat;
    height: 105px;
}

.bi-stats-wavedown {
    background: url(../../../images/services/bIntelligence/bg-wave-bottom.svg) center no-repeat;
    height: 110px;
    background-size: cover;
}

.bi-bg-bottom {
    background: #0F347E url(../../../images/services/bIntelligence/bg-bottom.svg) no-repeat top center;
    height: 750px;
}

img.nomobile {
    max-width: 990px;
    height: auto;
    margin: 0 auto;
    width: 85%
}

img.nodesktop {
    display: none
}

@media (max-width: 880px) {
    .bi-bg-bottom {
        height: 850px;
    }
    img.nomobile {
        display: none !important
    }
    img.nodesktop {
        display: block;
        width: 100%;
        max-width: 500px;
        margin: 0 auto
    }
}

@media (max-width: 768px) {
    .bi-report-container {
        height: 740px;
    }
    img.nomobile {
        display: none !important
    }
    img.nodesktop {
        display: block;
        width: 100%;
        max-width: 380px
    }
}

@media (max-width: 600px) {
    img.nomobile {
        display: none !important
    }
    img.nodesktop {
        max-width: 300px
    }
}