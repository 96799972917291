#contact {
    margin-top: 0;
    margin-bottom: 3%;

    #newsletter {
        width: 100%;
        height: 200px;
    }

    span, .form-check-label {
		font-weight: normal;
		font-size: 14px;
    }

    .interested-group {
        display: grid; /*Activa la propiedad*/
        grid-template-columns: repeat(2, 1fr); /*genera la división de columnas. Dos con la medida de 1 fracción*/
        grid-gap: 6px;
    }

    #contact-header {
        background-color: #13213C;
        color: white;
        text-align: center;
        display: block;
        height: 100%;
        padding-bottom: 60px;

		h2{
			color: white;
		}

        img {
            width: 100%;
            max-width: 225px;
            position: absolute;
            right: 100px;
            top: 40px;
            @media screen and (max-width: 1025px){
                max-width: 197px;
                right: 20px;
                top: 98px;
            }
        }
    }

    #contact-section {

        .g-recaptcha {
            display: flex;
            flex-direction: row-reverse;
        }

        .btn-lg {
            font-weight: bold;
		}

		#btn-contact-send{
			font-size: 20px;
			padding: 10px 30px;
		}
    }

    /* NEWSLATTER SECTION */
    #mc_embed_signup_widget {

        /* -Body- */
        background-color: #13213C;
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-wrap: wrap;
		padding: 5% 15%;

        /* -Botoom- */
        #mc-embedded-subscribe-widget {
            width: 100%;
            background-color: #8FC73E;
			font-weight: bold;
			font-size: 14px;
            height: 45px;
        }

        #mc-embedded-subscribe-form-widget {
            text-align: left;
            flex-grow: 2;
            display: flex;
            justify-content: initial;
            align-items: center;
        }

        #mce-EMAIL {
			height: 45px;
			padding: 10px 20px;
        }

        .mc-field-group {
			margin-right: 1%;
        }

        h2::before {
            content: "SUBSCRIBE TO OUR";
            font-size: 29px;
            color: white;
        }

        h2 {
            color: #8FC73E;
            font-size: 27px;
            font-weight: bold;
            text-transform: uppercase;
            flex-grow: 1;
            display: grid;
            justify-content: end;
            align-items: center;
            padding-right: 4%;
            grid-template-rows: 55px 55px;
            align-content: center;
        }
    }

    .col-sm-12 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .well {
        background-color: #13213C;
        border-color: #13213C;
        width: 100%;
        height: 200px;
    }

    .card {
        text-align: center;
    }

    .card-deck {
        display: flex;
    }

    .card-title {
        font-size: 20px;
        color: #13213C;
    }

    .btn-help {
        background-color: #13213C;
        width: 70%;
        height: 40px;
        padding-top: 3%;
    }

    .fab, .fas {
        color: #13213C;
        padding-right: 5%;
    }

    .label-news {
        display: inline-grid;
        width: 50%;
        color: white;
        font-size: 25px;
        text-align: center;
    }

    .input-news {
        width: 50%;
    }

    .label-strong {
        color: #8FC73E;
        font-size: 3.4rem;
    }

    .btn-news {
        background-color: #8FC73E;
        width: 35%;
        height: 58%;
        font-weight: bold;
    }

    .input-news {
        display: block;
        width: 100%;
        height: 40px;
        padding: .375rem .75rem;
        font-size: 1.5rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        margin-top: 9%;
    }

    .textCenter {
        padding-top: 2%;
        width: 100%;
    }
}

.bg-getNews {
    background-color: #0E2F71;
}

.contactus-cards {
    border: 0px;
}

.contactus-cards:hover {
    text-decoration: none;
    border-radius: 8px;
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
}

