.contentbox-head {
    background-image: url("../../../images/products/contentbox/bg-head.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-head-buttom {
    background: url("../../../images/products/contentbox/wave-bottom-headsection.svg") bottom center no-repeat;
}

.bg-create-content-buttom {
    background: #EFF6FF url("../../../images/products/contentbox/wave-botton-create-content-once.svg") bottom center no-repeat;
    background-size: contain;
}

.bg-content-management{
    background:  #fff url("../../../images/products/contentbox/wave-bottom-why-contentbox.svg") bottom no-repeat;
    background-size: 100%;
}

.bg-botton-queues{
    background: #1E3A8A url("../../../images/products/contentbox/wave-bottom-what-is-contentbox.svg") bottom no-repeat;
    background-size: 100%;
}

.wave-top-hmvc{
    background: url("../../../images/products/contentbox/wave-top-flexible-hmvc.svg") no-repeat;
    background-position-y: bottom;
    background-size: contain;
}

.wave-bottom-hmvc{
    background: #EEF2FF url("../../../images/products/contentbox/wave-bottom-flexible-hmvc.svg") no-repeat;
    background-position-y: bottom;
    background-size: cover;
}

.wave-top-download{
    background: #1E3A8A url("../../../images/products/contentbox/wave-top-download.svg") no-repeat;
    background-position-y: top;
    background-size: contain;
}


.bg-table-instalation {
      background-color: #f5f7f9;
}