.products-head-forgebox {
    background-image: url("../../../images/products/coldbox/bg-head.png");
    background-size: cover;
    background-position: bottom;
}

.text-blue-editions {
    color: #0F347E;
}

.text-ligthblue-editions {
    color: #007FAA;
}

.bg-header-editions {
    background-color: #C1D6EF;
}

#forgebox a:hover,
a:focus {
    text-decoration: none !important;
}

.forgebox-editions {
    background: url("../../../images/products/forgebox/wave-empowering-bottom.svg") top center no-repeat;
    padding-top: 150px;
    margin-top: 0px !important;
    background-size: 100%;
}

 .main-content {
    max-width: 1400px;
}

.products-apps-top {
    background: #007FAA url("../../../images/products/forgebox/wave-empowering-top.svg") top center no-repeat;
    margin-bottom: 0 !important;
    background-size: 100%;
}


.text-green-icon {
    color: $bright-green;
}

.text-gray-icon {
    color: #B7B7B7;
}

.bg-table-gray {
    background-color: #EFEFEF;
}

.bg-btn-blue {
    background-color: #0B3188;
    border: 2px solid #0B3188;
}

.bg-btn-blue:hover {
    background-color: #fff;
    color: $plain-black;
    border: 2px solid #0B3188;
}

#center-row > td {
    vertical-align: middle !important;
}

.margin-buttom-0 > tr > td > p, .margin-buttom-0 > tr > th > p {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.border-tbody {
    border: 1px solid #EFEFEF;
}

.border-tfoot {
    border: 2px solid #EFEFEF;
    border-bottom-right-radius: 8px solid #EFEFEF;
    border-bottom-left-radius: 8px solid #EFEFEF;
}

.ico-center {
    vertical-align: middle !important;
}

.products-got-questions {
    background: #0F347E url("../../../images/products/coldbox/wave-cbuniverse-top.svg") top center no-repeat;
    margin-bottom: 0 !important;
}

#question .got-question {
    background-size: 100%;
    margin-top: 112px !important;
}

.text-ligth-forgebox {
    color: #3B95FF;
}

.text-gray {
    color: #656565;
}

.border-tmobile {
    border: 2px solid #DBECFF;
}

.bg-skyblue {
    background-color: #DBECFF;
}

.btn-plan-top {
    border: 0px;
    border-radius: 10px 10px 0 0;
    background-color: #EFEFEF;
}

.btn-plan-bottom {
    border: 0px;
    border-radius: 0 0 10px 10px;
    background-color: #EFEFEF;
}

.plan-is-open .btn-plan-top, .plan-is-open .btn-plan-bottom {
    background-color: #DBECFF;
}

.btn-plan-ico-close {
    width: 35px;
    height: 35px;
}

.comparison-container {
    background-color: #DBECFF;
    border: 2px solid #DBECFF;
}

.table-container {
    padding: 25px 15px 25px;
    background: #fff;
    margin-top: -15px;
}

.table-compare {
    width: 100%;
    border: 0;
}

.table-compare tr:nth-child(even) {
    background: #fff
}

.table-compare tr:nth-child(odd) {
    background: #F0F6FC
}

.table-compare td {
    padding: 7px;
}

.table-compare td p {
    margin: 0px;
}

.plan-is-open .btn-plan-bottom .fa {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.plan-is-open .ttl-plan {
    color: #082766;
}

.btn-close{
    display: none;
}

.plan-is-open  .btn-close{
    display: inherit;
}

#forgebox .table-container {
    margin-top: 0px;
}