#about {
    .get-to-know {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    
    .team-div{
        background-color: #F7F7F7;
        padding-top: 50px;
        padding-bottom: 50px;
        

        h2 {
            font-size: 24px;
            padding: 30px 0;
            color: #757575;
        }
        .team-map{
            margin: 25px 0;
            .team-map-img {
                margin: 0 auto;
            }
            
        }

        .flex-container {
            display: flex; /* or inline-flex */
            flex-direction:row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            .profile-card-2 {
                background-color: white;
                margin-bottom: 25px;
                margin: 10px;
                .card-img-block{
                    width:100%;
                    max-height:225px;
                    overflow:hidden;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .card-body{
                    position:relative;
                    background-color: #fff;
                    padding: 10px;
                }
                .description {
                    position: relative;
                    display: none;
                    background-color: #fff;
                }
                .flag {
                    position: absolute;
                    top: -17px;
                    left: 49%;
                    max-width: 32px;
                    -webkit-transform: translate(-50%, 0%);
                    transform: translate(-50%, 0%);
                }
                h1{
                    font-weight:600;
                    color:$plain-black;
                    font-size: 18px;
                }
                h2 {
                    font-size: 14px;
                    color: $grey;
                    padding: 0;
                    font-weight: 300;
                    margin: 0;
                }
                .card-text{
                    font-weight:300;
                    font-size:14px;
                    color: $plain-black;
                }
                .icon-block{
                    width:100%;
                }
                .social {
                    font-size: 12px;
                    margin-top: 10px;
                }
            }
        }
        
    }

    .career-div{
        padding-top: 50px;
        background: -moz-linear-gradient(90deg, rgba(21,10,29,1) 0%, rgba(22,46,93,1) 100%); /* ff3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(22,46,93,1)), color-stop(100%, rgba(21,10,29,1))); /* safari4+,chrome */
        background: -webkit-linear-gradient(90deg, rgba(21,10,29,1) 0%, rgba(22,46,93,1) 100%); /* safari5.1+,chrome10+ */
        background: -o-linear-gradient(90deg, rgba(21,10,29,1) 0%, rgba(22,46,93,1) 100%); /* opera 11.10+ */
        background: -ms-linear-gradient(90deg, rgba(21,10,29,1) 0%, rgba(22,46,93,1) 100%); /* ie10+ */
        background: linear-gradient(0deg, rgba(21,10,29,1) 0%, rgba(22,46,93,1) 100%); /* w3c */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#162E5D', endColorstr='#150A1D',GradientType=0 ); /* ie6-9 */
        .title-careers{
            margin-bottom: 8%;
        }
        p{
            color: #fff;
        }
        .astronaut-img {
            margin: 0 auto;
            max-height: 450px;
        }
        .btn-primary {
            text-decoration: none;
            font-size: 15px;
            padding: 8px 20px;
            &:hover {
                background-color: transparent!important;
            }
            &:active {
                background-color: transparent!important;
            }
        }
    }
}