#cloud_servers {
    margin-top: 0 auto !important;
    $base-color: #0891B1;

    color: #515050;
    background-color: white;

    .container-fluid {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .rocket-height {
        height: 567px;
    }

    .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mt-1 {
        margin-top: 1%;
    }

    .mt-5 {
        margin-top: 5%;
    }

    .mt-10 {
        margin-top: 10%;
    }

    .w-50 {
        width: 50%;
    }

    .flex {
        display: flex;
    }

    .flex-colum {
        display: flex;
        flex-direction: column;
    }

    .font-bold {
        font-weight: bold;
    }

    .badge-light {
        width: 150px;
        height: 80px;
        font-size: 14px;
        border-radius: 25px;
        color: #757575;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .bg-white{
        background-color: white !important;
    }

    .header{
        width: 100%;
        height: 567px;
        background-image: url( "https://www.ortussolutions.com/__media/cloud-servers/top_bg_desk.png" );
        background-repeat: no-repeat;
        background-size: cover;
    }

    .cloud{
        width: 100%;
        background-image: url( "https://www.ortussolutions.com/__media/cloud-servers/clouds.png" );
        background-repeat: no-repeat;
        background-size: contain;
    }

    .commingSoon {
        color: #757575;
    }

    .whyCloud {
        color: #153E50;
    }

    .needHelp {
        color: #757575;
        padding-top: 5%;
    }

    .server {
        color: $base-color;
        font-weight: bold;
        font-size: 24px;
    }

    .details_content {
        padding: 3% 0% 3% 0%;
    }

    .rocket {
        height: 567px;
        background-image: url( 'https://www.ortussolutions.com/__media/cloud-servers/rocket_desk.png' );
        background-repeat: no-repeat;
        background-size: cover;
    }

    .rocket-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 51px;
        color: white;
        line-height: 1;
    }

    .awsMarketplace {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
        align-content: center;
        align-items: center;
    }

    /* *** Servers Tabs ***  */
    ul {
        width: 100%;
        display: flex;
    }

    #pills-tab > li {
        width: 50%;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
    }

    #pills-tab > li > a {
        padding: 3%;
    }

    .nav-pills > li > a {
        border-radius: 0px !important;
        color:$base-color;
    }

    a > i {
        font-size: 30px;
        margin-right: 1.5%;
    }

    .nav-pills > li + li {
        margin-left: 0px !important;
    }

    .nav-pills > li.active > a, .nav-pills > li.active > a:focus {
        color: #fff;
        background-color: $base-color;
    }

    .nav-pills > li {
        color: $base-color;
        background-color: #C9F5FF;
    }

    .details_header {
        background-color: #D8E8EE;
        padding: 20px 0px 20px 0px;
    }

    .amiWindows {
        width: 16%;
        margin-right: 3%;
    }

    .list {
        list-style: none;
        padding-left: 15px !important;
    }

    .list > li:before {
        content: '✓';
        margin-right: 1%;
    }

    .details_body {
        background-color: white;
        padding: 3% 1% 3% 1%;
    }

    .btn-server {
        color: white;
        margin: 0px !important;
        border-color: $base-color;
        background-color: $base-color;
    }

    .btn-server:hover {
        border: solid 1px;
        color: $base-color;
        background-color: transparent;
        border-color: $base-color;
        margin-bottom: 5%;
    }

    .btn-docs {
        border: solid 1px;
        color: $base-color;
        background-color: transparent;
        border-color: $base-color;
    }

    .btn-docs:hover {
        color: white;
        border-color: $base-color;
        background-color: $base-color;
    }

    .video_server {
        width: 528px;
        height: 297px;
    }

    /* *** Media Queries *** */
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        .rocket {
            height: 567px;
            background-image: url("https://www.ortussolutions.com/__media/cloud-servers/top_bg_mob.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-color: white;
        }

        .rocket-title {
            top: 25px;
            font-size: 26px;
            text-align: center;
            justify-content: start;
        }

        .rocket-height {
            height: 245px;
        }

        .amiWindows {
            width: 29%;
            margin-right: 0px;
            margin-bottom: 3%;
        }

        .server {
            font-size: 17px;
            text-align: center;
        }

        .flex_mobil {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
        }

        .video_server {
            width: 295px;
        }

        .badge-light {
            width: 275px;
            height: 46px;
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (min-width: 576px) and ( max-width: 812px ) {
        .header {
            height: 662px;
        }
        .rocket {
            width: 100%;
            height: 700px;
            background-image: url("https://www.ortussolutions.com/__media/cloud-servers/top_bg_mob.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-color: white;
        }

        .rocket-title {
            font-size: 55px;
            text-align: center;
        }

        .rocket-height {
            height: 325px;
        }

        .mt-5-mobil {
            margin-top: 5%;
        }

        .w-50 {
            width: 30%;
        }

        .amiWindows {
            width: 19%;
            margin-right: 0px;
            margin-bottom: 3%;
        }

        .server {
            font-size: 23px;
            text-align: center;
        }

        .flex_mobil {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
        }

        .video_server {
            width: 475px;
            margin-top: 5%;
        }

        .badge-light {
            width: 275px;
            height: 46px;
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (min-width: 813px) and ( max-width: 1024px ) {
        .header {
            height: 745px;
        }

        .rocket {
            width: 100%;
            height: 760px;
            background-image: url("https://www.ortussolutions.com/__media/cloud-servers/top_bg_mob.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-color: white;
        }

        .rocket-title {
            font-size: 55px;
            text-align: center;
        }

        .rocket-height {
            height: 350px;
        }

        .mt-5 {
            margin-top: 3%;
        }

        .mt-5-mobil {
            margin-top: 5%;
        }

        .w-50 {
            width: 30%;
        }

        .order-mobil {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        .btn-server {
            width: 300px;
        }

        .btn-docs {
            margin-top: 0px;
            width: 300px;
        }

        .amiWindows {
            width: 19%;
            margin-right: 0px;
            margin-bottom: 3%;
        }

        .server {
            font-size: 23px;
            text-align: center;
        }

        .flex_mobil {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
        }

        .video_server {
            width: 475px;
            margin-top: 5%;
        }

        .badge-light {
            width: 275px;
            height: 46px;
        }
    }
}