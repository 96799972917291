.text-color-boxlang {
    color: #00FF78;
}

.bg-color-boxlang, .boxlang-card {
    background-color: #0E0F1A;
}

.boxlang-card:hover {
    background-color: #2C2F50;
}