.bg-luce-head {
    background-image: url("../../../images/products/couchbase-lucee-extension/bg-head.png");
    background-size: cover;
    background-position: bottom;
}

.bg-bloc-ligthblue{
    background: #E4F1F6;
}

.list-margin ul {
    margin: 5px 22px;
}

.head-table-blue{
    background-color: #162E5D;
}

.btn-table-blue{
    background-color: #0B3188;
}

.btn-table-blue:hover{
    background-color: #3B95FF;
}

.border-gray-faq{
    border: 1px solid #D3D3D3;
}

.table-support{
    a {
        color: #fff;
    }
    a:hover{
        color: #fff;
        text-decoration: none;
    }
}

.accordion-ico{
    transition: transform 0.2s;
}

#couchbaseLuce{
    a:hover, a:focus{
        text-decoration: none;
    }
}

.fqa-bg-blue{
    background-color: #F4F7FF;
    border-color: #DBE1EF;  

    .text-dark-blue{
        color: $dark-blue;
        font-weight: bold;
    }

    .accordion-ico{
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        transition: transform 0.2s;
    }
}

.more-info-support:hover img {
    content: url("../../../images/products/couchbase-lucee-extension/ico-product-support-white.svg");
}

.more-info-server:hover img {
    content: url("../../../images/products/couchbase-lucee-extension/ico-server-white.svg");
}

#tableServers .table-container {
    margin-top: 0px; 
}

.text-warning{
    color: $warning;
}















