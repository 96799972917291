.teaser{
    --color-primary: #CEFF00;
    --color-surface: #1A1A1A;
    --body-color: #fff;

    .btn-primary {
        --btn-bg: var(--color-primary);
        --btn-color: var(--color-surface);
        --btn-border-color: var(--color-surface);
        --btn-border-width: 1px;
        --btn-font-weight: auto;
        --btn-text-transform: auto;
        --btn-active-bg: #fff;
        --btn-active-color: var(--color-primary);
        --btn-hover-bg: var(--color-surface);
        --btn-hover-color: #fff;
    }
    .btn-outline-primary {
        --btn-color: var(--color-primary);
        --btn-border-width: 1px;
        --btn-border-color: var(--color-primary);
        --btn-hover-bg: var(--color-primary);
        --btn-hover-color: var(--color-surface);

        color: var(--btn-color);
        border: var(--btn-border-width) solid var(--btn-border-color);

        &:hover, &:focus{
            background-color: var( --btn-hover-bg);
            color: var(--btn-hover-color);
            border: var(--btn-border-width) solid var(--btn-border-color);
        }
    }

    //teaser carrousel
    .carousel-inner {
        background: rgba(1, 1, 1, 0.9) url("../../images/bg-core-products.png") no-repeat center center;
        background-size: cover;
        border-radius: 35px;

        h2, h3{
            color: var(--body-color);
            margin-bottom: .75rem;
        }
        h2{
            font-size: 1.5rem;
        }
    }
    .carousel-content {
        display: flex;
        align-items: center;
        flex-direction: column;

        img{
            max-height: 241px;
            border-radius: 49px;
            padding: 20px;
        }
    }
    .carousel-indicators {
        --btn-border-radius:23px;
        --indicators-padding: 10px;

        border-radius: var(--btn-border-radius);
        padding: var(--indicators-padding);
        width: 80%;
        left: 40%;

        .btn {
            --btn-margin: 4px;
            --btn-border-radius:66px;
            --btn-padding-y: 0px;
            --btn-padding-x: var( --indicators-padding);
            padding: var(--btn-padding-y) var(--btn-padding-x);

            &:focus{
                outline: none;
            }
        }
        .active, .active:active {
            width: auto;
            height: auto;
            background-color: var(--color-surface);
            margin: var(--btn-margin);
        }
    }
}

.has-admin-bar {
    .teaser-mod{
        height: calc(100vh - 30px);
    }
}
.teaser-mod{
    height: calc(100vh - 90px);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    .teaser-text {
        height: calc(100vh - 655px);
    }
    .teaser-h1{
        font-family: 'Bungee', cursive;
        font-size: 7vmin;
        text-align: center;
        color: #fff;
        line-height: 1;
        animation: fadeIn ease-in 2s;
        -webkit-animation: fadeIn ease-in 2s;
        -moz-animation: fadeIn ease-in 2s;
        -o-animation: fadeIn ease-in 2s;
        -ms-animation: fadeIn ease-in 2s;

        .or-die{
            color: $primary;
        }
    }
    h2{
        font-size: 24px;
    }
    .scroll-down{
        color: #fff;
        font-size: 15px;
        text-align: center;

        .bounce{
            -moz-animation: 2s bounce infinite;
            -webkit-animation: 2s bounce infinite;
            animation: 2s bounce infinite;
            margin-right: 5px;
        }
    }

    .astronaut{
        z-index: 1;
        position: absolute;
        transform: translatey(0px);
        top: 0;
        right: 0;
        -moz-animation: 6s ease-in-out infinite;
        -webkit-animation: 6s ease-in-out infinite;
        animation: float 6s ease-in-out infinite;
        height: 30vh;
    }
}

.bg-boxlang{
	min-height: 65vh;
	background-image: url("../../images/bg-head-boxlang.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.text-primary-boxlang{
    color: #00FF78;
}
.teaser-mod:before{
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100%;
    min-height: 160px;
    left: 0;
    background: url("../../images/waves-teaser.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}


// MediaQueries //
@media (max-width: 600px) {
    .teaser .carousel-inner{
        height: 655px !important;
    }
}

@media (max-width: 915px){
    .teaser-mod{
        height: auto;

        .teaser-h1{
            font-size: 9vmin;
        }
    }

}

@media ( max-width: 1023px ){
    .teaser .carousel-inner{
        height: 577px;
    }
}
@media (min-width: 1024px){
    .teaser .carousel-content {
        min-height: 420px;
        max-height: 450px;
        flex-direction: row;
        justify-content: space-between;
        img {
            min-height: 250px;
            max-height: 330px;
        }
    }
    .teaser .carousel-inner {
        border-radius: 69px;
    }

    .teaser .carousel-indicators {
        margin-left: 0;
        left: 3% !important;
        width: auto !important;
        border-radius: 66px;
    }
}

@media (min-width: 769px){
    .teaser-mod{
        height: 730px;

        .teaser-h1{
            text-align: center;
        }
        .astronaut {
            height: 40vh;
        }
    }

    .teaser .carousel-indicators {
        width: 60%;
        left: 50%;
        bottom: 20px;
    }
}

@media (min-width: 991px){
    .teaser-mod{
        height: calc(100vh - 100px);
    }


}
@media (min-width: 1439px){
    .teaser-mod{
        .astronaut {
            right: 10%;
        }
    }
    .teaser .carousel-content img {
        max-height: 400px;
    }
}

@media (min-width: 1990px){
    .teaser-mod:before{
        min-height: 210px;
    }
}

// CSS animations //

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes bounce {
    0% {
        -webkit-transform: translate(0,-3px);
        transform: translate(0,-3px);
        }
        4% {
        -webkit-transform: translate(0,3px);
        transform: translate(0,3px);
        }
        8% {
        -webkit-transform: translate(0,-3px);
        transform: translate(0,-3px);
        }
        12% {
        -webkit-transform: translate(0,3px);
        transform: translate(0,3px);
        }
        16% {
        -webkit-transform: translate(0,-3px);
        transform: translate(0,-3px);
        }
        20% {
        -webkit-transform: translate(0,3px);
        transform: translate(0,3px);
        }
        22%, 100% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
        }
}

@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

