#stats {
    position: relative;
}
.stats-mod{
    background-color: #102143;
    background-image:url(../../images/stars-bg.svg);
    min-height: 500px;
    background-repeat: repeat;
    background-position: left bottom;
    padding-bottom: 100px;
    background-size: contain;
    background-attachment: fixed;
    &:before{
            content: '';
            position: absolute;
            bottom: -1px;
            width: 100%;
            min-height: 500px;
            left: 0;
            background: url(../../images/rocket-stats.svg);
            background-repeat: no-repeat;
            background-position: left bottom;
            background-size: 60%;
    }
    
    .detail-stats{
        margin-top: 150px;
        img {
            margin: 0 auto;
        }
    }
}
